import useIdentity from "@/features/ui/hooks/use-identity";
import { Box, Flex } from "@chakra-ui/react";
import AppLineSheetTypeSelector from "@/features/line-sheet-sets/app-line-sheet-type-selector";
import AppCompanySelect from "@/features/line-sheet-sets/app-company-select";
import AppButton from "@/features/line-sheet-sets/app-button";
import AppPagination from "@/features/line-sheet-sets/app-pagination";
import { DEFAULT_LIST_LINE_SHEET_PAIRS_PAGE_SIZE } from "@/features/line-sheet-sets/hooks/use-list-line-sheet-set-pairs";
import { useCallback, useEffect, useMemo, useRef } from "react";
import useAppToasts from "@/features/line-sheet-sets/hooks/use-app-toasts";
import { useRecoilValue } from "recoil";
import {
  agencyOrderSheetSetRowsMutateId,
  agencyOrderSheetSetRowsState,
} from "@/features/order-status/recoils/agency-order-sheet-set-rows-state";
import useAgencyOrderSheetSetRows from "@/features/order-status/hooks/use-agency-order-sheet-set-rows";
import AgencyOrderSheetSetGrid from "@/features/order-status/agency-order-sheet-set-grid";
import AppDatePicker from "@/features/line-sheet-sets/app-date-picker";
import AppListHeader from "@/features/ui/app-list-header";
import OrderSheetStatusUpdater, {
  UpdateOrderSheetSetStatusItem,
  UpdateOrderSheetStatusItem,
} from "@/features/order-status/order-sheet-status-updater";
import { findParentGridRow } from "@/features/order-sheet-sets/helpers/app-grid-row-helper";
import { AgencyLineSheetRow } from "@/features/order-sheet-sets/line-sheet-set-row.type";
import {
  AgencyOrderSheetRow,
  AgencyOrderSheetSetRow,
} from "@/features/order-status/order-sheet-set-row.type";
import useListDenseOrderSheetSet, {
  ListDenseOrderSheetSetsPathParameter,
  ListDenseOrderSheetSetsRequest,
} from "@/features/order-sheet-sets/hooks/use-list-dense-order-sheet-set";
import { toTitle } from "@/utils/case";
import { useTranslation } from "react-i18next";
import { GridMapperToRowOption } from "@/features/ui/grid-row/gird-mapper";
import AppSeasonMultiSelect from "@/features/line-sheet-sets/app-season-multi-select";
import { OrderByItem } from "@/features/types";
import { ListOrderSheetSetsRequest } from "@/features/line-sheet-sets/hooks/use-list-order-sheet-sets";
import useTypedSearchParams from "@/features/invoices/hooks/use-typed-search-params";

export interface PersistedOrderSheetSetListQuery
  extends Partial<ListOrderSheetSetsRequest> {}

export default function AgencyOrderSheetSetListPage() {
  const identity = useIdentity();
  const rowsMutateId = useRecoilValue(agencyOrderSheetSetRowsMutateId);
  const { error: showError, warning: showWarning } = useAppToasts();
  const rows = useRecoilValue(agencyOrderSheetSetRowsState);
  const { setRows, mutateRows } = useAgencyOrderSheetSetRows();
  const { t } = useTranslation();
  const [query, setQuery] =
    useTypedSearchParams<PersistedOrderSheetSetListQuery>();
  const toRowsOptionRef = useRef<GridMapperToRowOption | undefined>(undefined);

  // --- API: List Order Sheet Sets ---

  const listOrderSheetSetsKey = useMemo(():
    | {
        parameter: ListDenseOrderSheetSetsPathParameter;
        request: ListDenseOrderSheetSetsRequest;
      }
    | undefined => {
    const { pageNumber, ...rest } = query;

    let q: Partial<ListDenseOrderSheetSetsRequest> = {
      ...rest,
    };

    // if (status__eq === undefined) {
    //   q.status__in = OrderSheetStatuses.filter(
    //     (status) => status !== "WORKING_DRAFT" && status !== "INITIAL_DRAFT"
    //   );
    // } else {
    //   q.status__eq = status__eq;
    // }

    if (identity?.company) {
      return {
        parameter: {
          by: identity.company.type,
          companyId: identity.company.id,
        },
        request: {
          ...q,
          pageNumber: pageNumber || 1,
          pageSize: DEFAULT_LIST_LINE_SHEET_PAIRS_PAGE_SIZE,
          orderBy: [new OrderByItem("submittedAt", "descend")],
        },
      };
    }
  }, [identity, query]);

  const {
    isLoading: isListOrderSheetSetsLoading,
    data: listOrderSheetSetsData,
    error: listOrderSheetSetsError,
    mutate: mutateListOrderSheetSets,
  } = useListDenseOrderSheetSet(
    listOrderSheetSetsKey?.parameter,
    listOrderSheetSetsKey?.request,
    {
      shouldRetryOnError: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );

  useEffect(() => {
    if (listOrderSheetSetsError) {
      showError(listOrderSheetSetsError);
    }
  }, [listOrderSheetSetsError, showError]);

  useEffect(() => {
    setRows(listOrderSheetSetsData?.orderSheetSets, toRowsOptionRef.current);
    toRowsOptionRef.current = undefined;
  }, [listOrderSheetSetsData, setRows]);

  useEffect(() => {
    if (rowsMutateId > 0) {
      mutateListOrderSheetSets();
    }
  }, [mutateListOrderSheetSets, rowsMutateId]);

  // --- END: API: List Order Sheet Sets ---

  const updateOrderSheetItems = useMemo(():
    | UpdateOrderSheetSetStatusItem[]
    | UpdateOrderSheetStatusItem[] => {
    const orderSheetSetItems = (rows || [])
      .filter((row) => row.isChecked)
      .map((row): UpdateOrderSheetSetStatusItem => {
        return {
          id: row.id,
          status: row.status,
          orderSheets: row.children
            .filter((row) => row._rowType === "LineSheet")
            .flatMap((row) => row.children)
            .filter((row) => row._rowType === "OrderSheet")
            .filter((_row) => {
              const orderSheetRow = _row as AgencyOrderSheetRow;
              return (
                [
                  "CANCELED",
                  "MODIFICATION_REQUIRED",
                  "MODIFICATION_COMPLETED",
                ].indexOf(orderSheetRow.status) === -1
              );
            })
            .map((_row) => {
              const orderSheetRow = _row as AgencyOrderSheetRow;
              const lineSheetRow = findParentGridRow(
                orderSheetRow,
                rows
              ) as AgencyLineSheetRow;

              const orderSheetSetRow = findParentGridRow(
                lineSheetRow,
                rows
              ) as AgencyOrderSheetSetRow;
              return {
                id: orderSheetRow.id,
                name: orderSheetRow.name,
                confirmedAmount: orderSheetRow.confirmedAmount,
                confirmedQuantity: orderSheetRow.confirmedQuantity,
                issuedBy: {
                  name: orderSheetSetRow.issuedByBoutiqueName,
                },
                lineSheet: {
                  id: lineSheetRow.id,
                  status: lineSheetRow.status,
                },
                toBeSubmitted: orderSheetRow.toBeSubmitted,
              };
            }),
          createdByCompany: {
            name: row.orderedByBuyerName,
          },
        };
      });

    if (orderSheetSetItems.length > 0) {
      return orderSheetSetItems;
    } else {
      return (rows || []).flatMap((row) => {
        return row.children
          .filter((row) => row._rowType === "LineSheet")
          .flatMap((row) => row.children)
          .filter((row) => row._rowType === "OrderSheet") // 0
          .filter((row) => row.isChecked)
          .map((_orderSheetRow): UpdateOrderSheetStatusItem => {
            const orderSheetRow = _orderSheetRow as AgencyOrderSheetRow;
            const lineSheetRow = findParentGridRow(
              orderSheetRow,
              rows
            ) as AgencyLineSheetRow;
            const orderSheetSetRow = findParentGridRow(
              lineSheetRow,
              rows
            ) as AgencyOrderSheetSetRow;
            return {
              id: orderSheetRow.id,
              status: orderSheetRow.status,
              createdByCompany: {
                name: row.orderedByBuyerName,
              },
              orderSheetSetId: row.id,
              name: orderSheetRow.name,
              issuedBy: {
                name: orderSheetSetRow.issuedByBoutiqueName,
              },
              lineSheet: {
                id: lineSheetRow.id,
                status: lineSheetRow.status,
              },
              toBeSubmitted: orderSheetRow.toBeSubmitted,
            };
          });
      });
    }
  }, [rows]);

  const handleOrderSheetStatusUpdate = useCallback(async () => {
    const nextListOrderSheetSet = await mutateListOrderSheetSets();
    if (nextListOrderSheetSet !== listOrderSheetSetsData) {
      toRowsOptionRef.current = { discardIsChecked: true };
    }
  }, [listOrderSheetSetsData, mutateListOrderSheetSets]);

  return (
    <>
      <Flex
        flexDirection={"column"}
        gap={"12px"}
        height={"100%"}
        paddingBottom={"16px"}
      >
        <AppListHeader
          filters={[
            <AppLineSheetTypeSelector
              key={"AgencyOrderSheetSetListFilter_Type"}
              value={query["lineSheetSet.type__eq"]}
              onSelect={(value) =>
                setQuery((prev) => ({
                  ...prev,
                  "lineSheetSet.type__eq": value,
                  pageNumber: 1,
                }))
              }
            />,
            <AppSeasonMultiSelect
              key={"season"}
              value={query["lineSheetSet.season__in"]}
              onSelect={(value) =>
                setQuery((prev) => ({
                  ...prev,
                  "lineSheetSet.season__in": value,
                  pageNumber: 1,
                }))
              }
            />,
            <AppCompanySelect
              key={"AgencyOrderSheetSetListFilter_IssuedBy"}
              name={toTitle(t("issued_by"))}
              width={"144px"}
              types={["BOUTIQUE"]}
              id={query["lineSheetSet.issuedByCompany.id__eq"]}
              onSelect={(company) =>
                setQuery((prev) => ({
                  ...prev,
                  "lineSheetSet.issuedByCompany.id__eq": company?.id,
                  pageNumber: 1,
                }))
              }
            />,
            <AppCompanySelect
              key={"AgencyOrderSheetSetListFilter_OrderedBy"}
              name={toTitle(t("ordered_by"))}
              width={"144px"}
              types={["BUYER"]}
              id={query["createdByCompany.id__eq"]}
              onSelect={(company) =>
                setQuery((prev) => ({
                  ...prev,
                  "createdByCompany.id__eq": company?.id,
                  pageNumber: 1,
                }))
              }
            />,
            // <AppOrderSheetSetStatusSelector
            //   key={"AgencyOrderSheetSetListFilter_Status"}
            //   width={"144px"}
            //   value={query.status__eq}
            //   excludeStatuses={["WORKING_DRAFT", "INITIAL_DRAFT"]}
            //   onSelect={(v) => patchQuery("status__eq", v)}
            // />,
            <AppDatePicker
              key={"AgencyOrderSheetSetListFilter_OrderedOn"}
              width={"144px"}
              name={toTitle(t("ordered_on"))}
              value={query.submittedAt__eq}
              showTimeInput={false}
              onChange={(value) =>
                setQuery((prev) => ({
                  ...prev,
                  submittedAt__eq: value,
                }))
              }
            />,
            <AppButton
              key={"AgencyOrderSheetSetListFilter_ResetFilter"}
              variant={"normal"}
              size={"medium"}
              width={"144px"}
              onClick={() => {
                setQuery((prev) => {
                  return {
                    pageNumber: 1,
                  };
                });
              }}
            >
              {toTitle(t("reset_filter"))}
            </AppButton>,
          ]}
          actions={[
            <OrderSheetStatusUpdater
              key={"AgencyOrderSheetSetListAction_UpdateStatus"}
              items={updateOrderSheetItems}
              onUpdate={handleOrderSheetStatusUpdate}
            />,
          ]}
        />
        <Box
          flexGrow={1}
          overflow={"auto"}
          style={{
            scrollbarGutter: "stable",
          }}
        >
          <AgencyOrderSheetSetGrid />
        </Box>

        <Flex flexDirection={"row"} justifyContent={"center"}>
          <AppPagination
            capacity={10}
            pageSize={DEFAULT_LIST_LINE_SHEET_PAIRS_PAGE_SIZE}
            totalCount={listOrderSheetSetsData?.totalCount}
            pageNumber={query.pageNumber}
            setPageNumber={(value) =>
              setQuery((prev) => ({ pageNumber: value }))
            }
          />
        </Flex>
      </Flex>
    </>
  );
}
