import { createContext, ReactNode, useEffect, useMemo, useState } from "react";
import { DetailBrand } from "@/features/invoices/app-detail-brand-select";
import { NegativeSequencer } from "@/features/ui/utils/negative-sequencer";
import { OrderConfirmationInvoiceDetail } from "@/features/invoices/order-confirmation-invoices/order-confirmation-invoice.type";
import { uniqBy } from "lodash";
import { BoutiqueConfirmationMetadata } from "@/features/order-sheets/order-sheet.type";
import useIdentity from "@/features/ui/hooks/use-identity";
import useListAllCompanies, {
  ListAllCompaniesPathParameter,
  ListAllCompaniesRequest,
} from "@/features/line-sheet-sets/hooks/use-list-all-companies";

interface DetailBrandProviderProps {
  children: ReactNode;
  orderConfirmationInvoices: {
    id: number;
    name: string;
    orderConfirmationInvoiceDetailList: OrderConfirmationInvoiceDetail[];
  }[];
  orderSheetSets: {
    id: number;
    lineSheetSet: {
      id: number;
      name: string;
    };
    boutiqueConfirmationMetadataList: BoutiqueConfirmationMetadata[] | null;
  }[];
  issuedBy?: { id: number };
  negativeSequencer: NegativeSequencer;
}

export interface DetailBrandContextType {
  items: DetailBrand[];
}

export const DetailBrandContext = createContext<DetailBrandContextType>({
  items: [],
});

export default function DetailBrandProvider({
  children,
  negativeSequencer,
  orderConfirmationInvoices,
  orderSheetSets,
  issuedBy,
}: DetailBrandProviderProps) {
  const [items, setItems] = useState<DetailBrand[]>([]);

  const identity = useIdentity();
  const key = useMemo(():
    | {
        parameter: ListAllCompaniesPathParameter;
        request: ListAllCompaniesRequest;
      }
    | undefined => {
    if (
      identity?.company &&
      orderSheetSets.length === 0 &&
      orderConfirmationInvoices.length === 0
    ) {
      return {
        parameter: {
          by: identity.company.type,
          companyId: identity.company.id,
        },
        request: {
          type__in: ["BRAND"],
        },
      };
    }
  }, [identity, orderConfirmationInvoices.length, orderSheetSets.length]);

  const { data: brands } = useListAllCompanies(key?.parameter, key?.request);

  useEffect(() => {
    if (key) {
      setItems(() => {
        return (brands || []).map((brand): DetailBrand => {
          return {
            id: negativeSequencer.next(),
            brand: brand,
          };
        });
      });
    }
  }, [brands, key, negativeSequencer]);

  useEffect(() => {
    setItems(() => {
      let nextItems: DetailBrand[] = [];

      if (orderConfirmationInvoices.length > 0) {
        nextItems = [
          ...nextItems,
          ...orderConfirmationInvoices.flatMap((ocInvoice) => {
            return ocInvoice.orderConfirmationInvoiceDetailList.map(
              (ocDetail): DetailBrand => {
                // ocDetail.orderSheetSet?.id
                return {
                  id: negativeSequencer.next(),
                  brand: ocDetail.brand,
                  orderConfirmationInvoiceDetail: {
                    ...ocDetail,
                    orderConfirmationInvoice: {
                      id: ocInvoice.id,
                      name: ocInvoice.name,
                    },
                  },
                };
              }
            );
          }),
        ];
      }

      if (orderSheetSets.length > 0 && issuedBy) {
        nextItems = [
          ...nextItems,
          ...orderSheetSets
            .filter(
              (
                oss
              ): oss is Omit<
                DetailBrandProviderProps["orderSheetSets"][number],
                "boutiqueConfirmationMetadataList"
              > & {
                boutiqueConfirmationMetadataList: BoutiqueConfirmationMetadata[];
              } => oss.boutiqueConfirmationMetadataList !== null
            )
            .flatMap((oss) => {
              return uniqBy(
                oss.boutiqueConfirmationMetadataList
                  .filter(
                    (metadata) => metadata.toBeSubmitted.id === issuedBy.id
                  )
                  .flatMap((metadata) =>
                    metadata.conditions.map((condition) => condition.brand)
                  ),
                (brand) => brand.id
              ).map((brand): DetailBrand => {
                return {
                  id: negativeSequencer.next(),
                  brand: brand,
                  orderSheetSet: {
                    id: oss.id,
                    lineSheetSet: {
                      id: oss.lineSheetSet.id,
                      name: oss.lineSheetSet.name,
                    },
                  },
                };
              });
            }),
        ];
      }

      if (
        orderSheetSets.length === 0 &&
        orderConfirmationInvoices.length === 0 &&
        brands
      ) {
        nextItems = [
          ...nextItems,
          ...brands.map((brand): DetailBrand => {
            return {
              id: negativeSequencer.next(),
              brand: brand,
            };
          }),
        ];
      }

      return nextItems;
    });
  }, [
    brands,
    issuedBy,
    negativeSequencer,
    orderConfirmationInvoices,
    orderSheetSets,
  ]);

  return (
    <DetailBrandContext.Provider value={{ items }}>
      {children}
    </DetailBrandContext.Provider>
  );
}
