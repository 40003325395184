import { SheetProduct } from "@/features/order-sheets/sheet.type";
import { atomFamily, selectorFamily } from "recoil";
import { SheetFlattener } from "@/features/line-sheet-sets/helpers/sheet-flattener";
import {
  ConditionType,
  FooStatistic,
} from "@/features/order-sheets/order-sheet.type";
import {
  LightLineSheetSet,
  PriceAdjustedBy,
} from "@/features/line-sheet-sets/line-sheet-set.types";
import { MathUtils } from "@/features/ui/utils/math-utils";
import { ProductPrice } from "@/features/types";
import { min } from "lodash";
import { AgGridReact } from "ag-grid-react";
import { c } from "msw/lib/glossary-de6278a9";

export interface InflatedTab {
  index: number;
  name: string;
  products: InflatedProduct[];
  isDirty: boolean;
}

export type InflatedProduct = SheetProduct & { isFiltered: boolean };

export interface FlatProductImageUrl {
  productId: number;
  url: string;
  type: "ORIGINAL" | "ALTERNATIVE";
}

export function isFlatProductImageUrl(item: {
  url: string;
  type: string;
}): item is FlatProductImageUrl {
  return item.type === "ORIGINAL" || item.type === "ALTERNATIVE";
}

export interface FlatProduct {
  id: number;
  rowId: string;
  imageUrls: FlatProductImageUrl[] | null;
  brandModelNumberModelName: string | null;
  genderCategorySubcategory: string | null;
  colorCodeColorNameMaterial: string | null;
  priceAmount: number | OverlappedValue<number>;
  priceCurrency: string | null;
  priceAdjustedByPartial:
    | PriceAdjustedBy["by"]
    | PriceAdjustedBy["value"]
    | OverlappedValue<PriceAdjustedBy["by"]>
    | OverlappedValue<PriceAdjustedBy["value"]>
    | null;
  sizes: string[] | number[] | OverlappedValue<number>[] | null;
  isFiltered: boolean;
}

export interface OverlappedValue<T> {
  top: T;
  under: T;
  colorCode?: string;
}

export function isOverlappedValue<T>(
  value: any,
  predicate: (item: any) => boolean
): value is OverlappedValue<T> {
  return (
    value !== null &&
    value !== undefined &&
    value.top !== undefined &&
    value.under !== undefined &&
    predicate(value.top) &&
    predicate(value.under)
  );
}

export interface FlatSheetTab {
  index: number;
  name: string;
  isDirty: boolean;
  products: FlatProduct[];
}

export type LineSheetSetKey = {
  lineSheetSetId: number;
};

export type FlatSheetKey = LineSheetFlatSheetKey | OrderSheetFlatSheetKey;

export type LineSheetFlatSheetKey = {
  lineSheetSetId: number;
  lineSheetId: number;
};

export type OrderSheetFlatSheetKey = {
  orderSheetSetId: number;
  orderSheetId: number;
  revisionNumber?: number;
};

export function isLineSheetFlatSheetKey(
  key: LineSheetFlatSheetKey | OrderSheetFlatSheetKey
): key is LineSheetFlatSheetKey {
  const value = key as LineSheetFlatSheetKey;
  return value.lineSheetSetId !== undefined && value.lineSheetId !== undefined;
}

export function isOrderSheetFlatSheetKey(
  key: LineSheetFlatSheetKey | OrderSheetFlatSheetKey
): key is OrderSheetFlatSheetKey {
  const value = key as OrderSheetFlatSheetKey;
  return (
    value.orderSheetSetId !== undefined &&
    value.orderSheetId !== undefined &&
    value.revisionNumber !== undefined
  );
}

export interface LineSheetSetState {
  key: LineSheetSetKey;
  lineSheetSet: LightLineSheetSet;
}

export interface InflatedTabsState {
  key: FlatSheetKey;
  tabs: InflatedTab[];
}

export interface FlatTabsState {
  key: FlatSheetKey;
  tabs: FlatSheetTab[];
}

export const agGridRefFamily = atomFamily<
  AgGridReact<FlatProduct> | undefined,
  any
>({
  key: "agGridRefFamily",
  default: undefined,
});

export const lineSheetSetFamily = atomFamily<
  LineSheetSetState | undefined,
  LineSheetSetKey
>({
  key: "lineSheetSet",
  default: undefined,
});

export const inflatedTabsFamily = atomFamily<
  InflatedTabsState | undefined,
  FlatSheetKey
>({
  key: "InflatedTabs",
  default: undefined,
});

export const flatTabsFamily = selectorFamily<
  FlatTabsState | undefined,
  FlatSheetKey
>({
  key: "FlatTabs",
  get:
    (param: FlatSheetKey) =>
    ({ get }) => {
      const inflatedTabsState = get(inflatedTabsFamily(param));
      if (inflatedTabsState) {
        const flattener = new SheetFlattener();
        return {
          key: inflatedTabsState.key,
          tabs: flattener.flattenTabs(inflatedTabsState.tabs),
        };
      }
    },
  // set:
  //   (param) =>
  //   ({ set, get }) => {
  //     const flatTabs = get(flatTabsFamily(param));
  //     set(inflatedTabsFamily(param), (inflatedTabsState) => {
  //       const flatTabsState = get(flatTabsFamily(param));
  //       if (flatTabsState) {
  //         const inflater = new SheetInflater();
  //         return {
  //           key: flatTabsState.key,
  //           tabs: inflater.inflateTabs(flatTabsState.tabs),
  //         };
  //       }
  //       return undefined;
  //     });
  //   },
});

export interface SheetStatisticState {
  key: FlatSheetKey;
  statistic: FooStatistic;
}

export const clientSheetStatisticFamily = selectorFamily<
  SheetStatisticState | undefined,
  FlatSheetKey
>({
  key: "ClientSheetStatistic",
  get:
    (param) =>
    ({ get }) => {
      const inflatedTabsState = get(inflatedTabsFamily(param));

      function reduceStatistic(
        acc: FooStatistic,
        i: FooStatistic
      ): FooStatistic {
        let firstOrderedConditionType;

        if (i.totalOrderedQuantity === 0) {
          firstOrderedConditionType = acc.firstOrderedConditionType;
        } else if (acc.firstOrderedConditionType == null) {
          firstOrderedConditionType = i.firstOrderedConditionType;
        } else if (acc.firstOrderedConditionType === i.orderedConditionType) {
          firstOrderedConditionType = acc.firstOrderedConditionType;
        } else {
          firstOrderedConditionType = "MIX" as ConditionType;
        }
        let orderedConditionType;

        if (i.totalOrderedQuantity === 0) {
          orderedConditionType = acc.orderedConditionType;
        } else if (acc.orderedConditionType == null) {
          orderedConditionType = i.orderedConditionType;
        } else if (acc.orderedConditionType === i.orderedConditionType) {
          orderedConditionType = acc.orderedConditionType;
        } else {
          orderedConditionType = "MIX" as ConditionType;
        }

        let confirmedConditionType;
        if (i.totalOrderedQuantity === 0) {
          confirmedConditionType = acc.confirmedConditionType;
        } else if (acc.confirmedConditionType == null) {
          confirmedConditionType = i.confirmedConditionType;
        } else if (acc.confirmedConditionType === i.confirmedConditionType) {
          confirmedConditionType = acc.confirmedConditionType;
        } else {
          confirmedConditionType = "MIX" as ConditionType;
        }

        return {
          firstOrderedQuantity:
            acc.firstOrderedQuantity !== null || i.firstOrderedQuantity !== null
              ? MathUtils.trim(
                  (acc.firstOrderedQuantity || 0) +
                    (i.firstOrderedQuantity || 0)
                )
              : null,
          firstOrderedAmount:
            acc.firstOrderedAmount !== null || i.firstOrderedAmount !== null
              ? MathUtils.trim(
                  (acc.firstOrderedAmount || 0) + (i.firstOrderedAmount || 0),
                  3
                )
              : null,
          firstOrderedAmountWithoutCondition:
            acc.firstOrderedAmountWithoutCondition !== null ||
            i.firstOrderedAmountWithoutCondition !== null
              ? MathUtils.trim(
                  (acc.firstOrderedAmountWithoutCondition || 0) +
                    (i.firstOrderedAmountWithoutCondition || 0),
                  3
                )
              : null,
          firstOrderedConditionType: firstOrderedConditionType,
          totalOrderedQuantity:
            acc.totalOrderedQuantity + i.totalOrderedQuantity,
          totalOrderedAmount: MathUtils.trim(
            acc.totalOrderedAmount + i.totalOrderedAmount,
            3
          ),
          totalOrderedAmountWithoutCondition: MathUtils.trim(
            acc.totalOrderedAmountWithoutCondition +
              i.totalOrderedAmountWithoutCondition,
            3
          ),
          orderedConditionType: orderedConditionType,
          totalConfirmedAmount:
            acc.totalConfirmedAmount !== null || i.totalConfirmedAmount !== null
              ? MathUtils.trim(
                  (acc.totalConfirmedAmount || 0) +
                    (i.totalConfirmedAmount || 0),
                  3
                )
              : null,
          totalConfirmedQuantity:
            acc.totalConfirmedQuantity !== null ||
            i.totalConfirmedQuantity !== null
              ? (acc.totalConfirmedQuantity || 0) +
                (i.totalConfirmedQuantity || 0)
              : null,
          totalConfirmedAmountWithoutCondition:
            acc.totalConfirmedAmountWithoutCondition !== null ||
            i.totalConfirmedAmountWithoutCondition !== null
              ? MathUtils.trim(
                  (acc.totalConfirmedAmountWithoutCondition || 0) +
                    (i.totalConfirmedAmountWithoutCondition || 0),
                  3
                )
              : null,
          confirmedConditionType: confirmedConditionType,
        };
      }

      function foo(
        supplyPrice: ProductPrice | null,
        costPrice: ProductPrice | null,
        retailPrice: ProductPrice | null,
        priceAdjustedBy: PriceAdjustedBy | null,
        quantityWithOptionList: { quantity: number }[] | null
      ) {
        let totalQuantity = null;
        let totalAmount = null;
        let totalAmountWithoutCondition = null;
        if (quantityWithOptionList) {
          totalQuantity = quantityWithOptionList
            ?.map((i) => i.quantity)
            .reduce((acc, i) => acc + i, 0);
        }

        if (supplyPrice) {
          totalAmount =
            totalQuantity !== null
              ? MathUtils.trim(supplyPrice.amount * totalQuantity, 3)
              : null;

          if (priceAdjustedBy) {
            const priceWithoutCondition =
              priceAdjustedBy.by === "MARKUP"
                ? costPrice?.amount
                : retailPrice?.amount;

            totalAmountWithoutCondition =
              totalQuantity !== null && priceWithoutCondition !== undefined
                ? MathUtils.trim(priceWithoutCondition * totalQuantity, 3)
                : null;
          }
        }

        return {
          totalQuantity,
          totalAmount,
          totalAmountWithoutCondition,
          conditionType:
            totalQuantity || 0 > 0 ? priceAdjustedBy?.by : null || null,
        };
      }

      if (inflatedTabsState) {
        return {
          key: param,
          statistic: inflatedTabsState.tabs
            .map((tab): FooStatistic => {
              return tab.products
                .map((product): FooStatistic => {
                  const supplyPrice = product.supplyPrice;
                  const costPrice = product.costPrice;
                  const retailPrice = product.retailPrice;
                  const confirmedPrice = product.confirmedPrice;

                  const firstOrdered = foo(
                    supplyPrice,
                    costPrice,
                    retailPrice,
                    product.priceAdjustedBy,
                    product.firstOrderQuantityWithOptionList
                  );

                  const orderQuantityWithOptionList =
                    product.orderQuantityWithOptionList.map((i, index) => {
                      if (product.newStockWithOptionList !== null) {
                        const newStock =
                          product.newStockWithOptionList[index]?.quantity ||
                          i.quantity;
                        const confirmedQuantity =
                          product.confirmedQuantityWithOptionList
                            ? product.confirmedQuantityWithOptionList[index]
                                ?.quantity
                            : null;
                        const latestOrderQuantity =
                          product.latestOrderQuantityWithOptionList
                            ? product.latestOrderQuantityWithOptionList[index]
                                ?.quantity
                            : null;
                        if (
                          newStock !== null &&
                          latestOrderQuantity !== null &&
                          i.quantity < latestOrderQuantity
                        ) {
                          // 왜 여기서 min([latestOrderQuantity, newStock]) || i.quantity를 리턴하지?
                          // 이렇게 되면 직전에 3개 주문했었는데, 주문 취소한 다음에, 주문 초기화를 했을 때, 주문한 수량은 0개인데 통계에서는 3개가 보임.
                          // 일단 i.quantity를 리턴하도록 수정함.
                          return {
                            quantity:
                              // min([latestOrderQuantity, newStock]) ||
                              i.quantity,
                          };
                        }
                        if (
                          newStock !== null &&
                          newStock < i.quantity &&
                          newStock != -1
                        ) {
                          return {
                            quantity: newStock,
                          };
                        }
                        if (
                          newStock !== null &&
                          newStock >= i.quantity &&
                          newStock != -1
                        ) {
                          return {
                            quantity: i.quantity,
                          };
                        }
                      }
                      return {
                        quantity: i.quantity,
                      };
                    }) || null;

                  const ordered = foo(
                    confirmedPrice || supplyPrice,
                    costPrice,
                    retailPrice,
                    product.confirmedPriceAdjustedBy || product.priceAdjustedBy,
                    orderQuantityWithOptionList
                  );

                  const confirmed = foo(
                    confirmedPrice,
                    costPrice,
                    retailPrice,
                    product.confirmedPriceAdjustedBy,
                    product.confirmedQuantityWithOptionList
                  );

                  return {
                    firstOrderedQuantity: firstOrdered.totalQuantity,
                    firstOrderedAmount: firstOrdered.totalAmount,
                    firstOrderedAmountWithoutCondition:
                      firstOrdered.totalAmountWithoutCondition,
                    firstOrderedConditionType:
                      firstOrdered.conditionType || null,
                    totalOrderedQuantity: ordered.totalQuantity || 0,
                    totalOrderedAmount: ordered.totalAmount || 0,
                    totalOrderedAmountWithoutCondition:
                      ordered.totalAmountWithoutCondition || 0,
                    orderedConditionType: ordered.conditionType!,
                    totalConfirmedAmount: confirmed.totalAmount,
                    totalConfirmedQuantity: confirmed.totalQuantity,
                    totalConfirmedAmountWithoutCondition:
                      confirmed.totalAmountWithoutCondition,
                    confirmedConditionType:
                      product.confirmedPriceAdjustedBy?.by || null,
                  };
                })
                .reduce(reduceStatistic);
            })
            .reduce(reduceStatistic),
        };
      }

      return {
        key: param,
        statistic: {
          firstOrderedAmount: null,
          firstOrderedQuantity: null,
          firstOrderedAmountWithoutCondition: null,
          firstOrderedConditionType: null,
          totalOrderedQuantity: 0,
          totalOrderedAmount: 0,
          totalOrderedAmountWithoutCondition: 0,
          orderedConditionType: "MIX",
          totalConfirmedQuantity: null,
          totalConfirmedAmount: null,
          totalConfirmedAmountWithoutCondition: null,
          confirmedConditionType: null,
        },
      };
    },
});
