import { LightLineSheet } from "@/features/line-sheet-sets/line-sheet-set.types";
import {
  DenseOrderSheet,
  DenseOrderSheetSet,
  isOrderedToAgency,
} from "@/features/order-sheets/order-sheet.type";
import { Boutique, StoredObject } from "@/features/types";
import { uniq } from "lodash";
import {
  AgencyAttachmentRow,
  AgencyLineSheetRow,
} from "@/features/order-sheet-sets/line-sheet-set-row.type";
import {
  AgencyOrderSheetRow,
  AgencyOrderSheetSetRow,
} from "@/features/order-status/order-sheet-set-row.type";
import {
  GridMapper,
  GridMapperToRowOption,
} from "@/features/ui/grid-row/gird-mapper";

export const agencyOrderSheetSetRowsMapper = new GridMapper<
  DenseOrderSheetSet,
  AgencyOrderSheetSetRow
>(
  "OrderSheetSet",
  (orderSheetSet) => {
    const { lineSheetSet } = orderSheetSet;

    const isCheckable =
      ["ORDERED_TO_AGENCY", "AGENCY_CONFIRMED", "ORDERED_TO_BOUTIQUE"].includes(
        orderSheetSet.status
      ) &&
      uniq(
        orderSheetSet.orderSheets
          .filter(
            (orderSheet) =>
              ![
                "CANCELED",
                "MODIFICATION_REQUIRED",
                "MODIFICATION_COMPLETED",
              ].includes(orderSheet.status)
          )
          .map((v) => v.status)
      ).length === 1;

    return {
      season: lineSheetSet.season,
      submittedAt: orderSheetSet.submittedAt!!,
      issuedByBoutiqueName: lineSheetSet.issuedByBoutique.name,
      orderedByBuyerName: orderSheetSet.createdByCompany.name,
      name: lineSheetSet.name,
      status: orderSheetSet.status,
      type: lineSheetSet.type,
      currency: lineSheetSet.currency,
      validUntil: lineSheetSet.validUntil,
      lineSheetSetId: lineSheetSet.id,
      isCheckable: isCheckable,
      isCheckableOriginal: isCheckable,
      isRead: orderSheetSet.isRead,
      orderedConditionType: orderSheetSet.orderedConditionType,
      orderedAmountWithoutCondition:
        orderSheetSet.orderedAmountWithoutCondition,
      orderedAmount: orderSheetSet.orderedAmount,
      orderedQuantity: orderSheetSet.orderedQuantity,
      confirmedAmount: orderSheetSet.confirmedAmount,
      confirmedQuantity: orderSheetSet.confirmedQuantity,
      compressedExportedFile: orderSheetSet.compressedExportedFile,
      issuedByBoutique: lineSheetSet.issuedByBoutique,
      lineSheetSet,
    };
  },
  (row) => {
    return {
      submittedAt: row.submittedAt,
      issuedByBoutique: {
        id: -1,
        type: "BOUTIQUE",
        name: row.issuedByBoutiqueName,
      },
      createdByCompany: {
        id: -1,
        type: "BUYER",
        name: row.orderedByBuyerName,
      },
      status: row.status,
      lineSheetSet: {
        id: row.lineSheetSetId,
        type: row.type,
        name: row.name,
      },
    };
  }
)
  .addChild(
    (
      orderSheetSet
    ): (LightLineSheet & {
      _isWritable: boolean;
      _orderSheets: DenseOrderSheet[];
    })[] => {
      const { lineSheetSet } = orderSheetSet;
      const isOrdered = isOrderedToAgency(orderSheetSet);

      return orderSheetSet.lineSheetSet.lineSheets.map((lineSheet) => {
        const orderSheets = orderSheetSet.orderSheets.filter(
          (orderSheet) => orderSheet.lineSheet.id === lineSheet.id
        );

        let isWritable = !isOrdered;

        if (!isOrdered) {
          isWritable =
            lineSheetSet.type === "SEASON" || orderSheets.length === 0;
        }

        return {
          ...lineSheet,
          _isWritable: isWritable,
          _orderSheets: orderSheets,
        };
      });
    },
    (parent, child) => {
      parent.lineSheetSet!.lineSheets = child;
    },
    new GridMapper<
      LightLineSheet & {
        _isWritable: boolean;
        _orderSheets: DenseOrderSheet[];
      },
      AgencyLineSheetRow
    >(
      "LineSheet",
      (lineSheet) => {
        return {
          name: lineSheet.name,
          type: lineSheet.type,
          isFallback:
            lineSheet.status === "PARSING_SKIPPED" ||
            lineSheet.status === "PARSING_FAILED",
          status: lineSheet.status,
          isWritable: lineSheet._isWritable,
          file: lineSheet.file,
          unoFormFile: lineSheet.unoFormFile,
          updatedAt: lineSheet.updatedAt,
          reviewStatus: lineSheet.reviewStatus,
        };
      },
      (row) => {
        return {
          name: row.name,
          type: row.type,
          status: row.status,
          updatedAt: row.updatedAt,
        };
      }
    ).addChild(
      (lineSheet) => {
        return lineSheet._orderSheets;
      },
      (parent, child) => {},
      new GridMapper<DenseOrderSheet, AgencyOrderSheetRow>(
        "OrderSheet",
        (orderSheet) => {
          const { lineSheet } = orderSheet;

          const isCheckable = [
            "ORDERED_TO_AGENCY",
            "AGENCY_CONFIRMED",
            "ORDERED_TO_BOUTIQUE",
          ].includes(orderSheet.status);

          return {
            name: lineSheet.name,
            toBeSubmitted: orderSheet.toBeSubmitted,
            isFallback:
              lineSheet.status === "PARSING_SKIPPED" ||
              lineSheet.status === "PARSING_FAILED",
            status: orderSheet.status,
            uploadedFile: orderSheet.uploadedFile,
            exportedFile: orderSheet.exportedFile,
            lineSheetId: lineSheet.id,
            isCheckable: isCheckable,
            isCheckableOriginal: isCheckable,
            orderedConditionType: orderSheet.orderedConditionType,
            orderedAmountWithoutCondition:
              orderSheet.orderedAmountWithoutCondition,
            orderedAmount: orderSheet.orderedAmount,
            orderedQuantity: orderSheet.orderedQuantity,
            confirmedAmount: orderSheet.confirmedAmount,
            confirmedQuantity: orderSheet.confirmedQuantity,
          };
        },
        (row) => {
          return {
            name: row.name,
            toBeSubmitted: row.toBeSubmitted as Boutique | null,
            status: row.status,
            uploadedFile: row.uploadedFile,
            exportedFile: row.exportedFile,
          };
        }
      )
    )
  )
  .addChild(
    (orderSheetSet) => {
      return orderSheetSet.lineSheetSet.attachments;
    },
    (parent, child) => {
      parent.lineSheetSet!.attachments = child;
    },
    new GridMapper<StoredObject, AgencyAttachmentRow>(
      "Attachment",
      (attachment) => {
        return {
          name: attachment.name,
        };
      },
      (row) => {
        return {
          name: row.name,
        };
      }
    )
  );

export function createAgencyOrderSheetSetRows(
  orderSheetSets?: DenseOrderSheetSet[],
  prevRows?: AgencyOrderSheetSetRow[],
  option?: GridMapperToRowOption
) {
  return agencyOrderSheetSetRowsMapper.toRows(
    orderSheetSets,
    prevRows,
    undefined,
    option
  );
}
