import {
  Agency,
  Boutique,
  Brand,
  ProductPrice,
  QuantityWithOption,
  StoredObject,
} from "@/features/types";
import {
  ConditionType,
  LightOrderSheet,
  LightOrderSheetSet,
  OrderSheetSet,
} from "@/features/order-sheets/order-sheet.type";
import { SheetProduct } from "@/features/order-sheets/sheet.type";

export interface LineSheetProduct {
  id: number;
  sku: string | null;
  modelNumber: string | null;
  name: string | null;
  brandName: string | null;
  categories: string[];
  gender: string | null;
  colorCode: string | null;
  colorName: string | null;
  material: string | null;
  season: string | null;
  supplyPrice: ProductPrice | null; //stock => retailPrice, season => costPrice
  costPrice: ProductPrice | null; //<<
  retailPrice: ProductPrice | null; //<<
  imageUrls: string[];
  alternativeImageUrls: string[];
  note: { [key: string]: string } | null;
  stockQuantityWithOptionList: QuantityWithOption[];
  priceAdjustedBy: PriceAdjustedBy;
}

export function isSheetProduct(
  product: SheetProduct | LineSheetProduct
): product is SheetProduct {
  return (product as SheetProduct).orderQuantityWithOptionList !== undefined;
}

export interface PriceAdjustedBy {
  by: ConditionType;
  value: number;
}

export function isPriceAdjustedBy(obj: any): obj is PriceAdjustedBy {
  const priceAdjustedBy = obj as PriceAdjustedBy;
  return (
    priceAdjustedBy !== undefined &&
    priceAdjustedBy !== null &&
    (priceAdjustedBy.by === "MARKUP" || priceAdjustedBy.by === "DISCOUNT") &&
    priceAdjustedBy.value !== undefined &&
    typeof priceAdjustedBy.value === "number"
  );
}

export function isPriceAdjustedByType(str: any): str is PriceAdjustedBy["by"] {
  return str === "MARKUP" || str === "DISCOUNT";
}

export type LineSheetSetStatus =
  | "PARSING_PENDING"
  | "PARSING_COMPLETED"
  | "PARSING_FAILED"
  | "PARSING_SKIPPED";

export const LineSheetSetStatuses: LineSheetSetStatus[] = [
  "PARSING_PENDING",
  "PARSING_COMPLETED",
  "PARSING_FAILED",
  "PARSING_SKIPPED",
];

export function isLineSheetSetStatus(
  value: string
): value is LineSheetSetStatus {
  return LineSheetSetStatuses.some((t) => t === value);
}

export type LineSheetStatus =
  | "PARSING_SKIPPED"
  | "PARSING_PENDING"
  | "PARSING_FAILED"
  | "PARSED";

export const LineSheetStatuses: LineSheetStatus[] = [
  "PARSING_SKIPPED",
  "PARSING_PENDING",
  "PARSING_FAILED",
  "PARSED",
];

export type LineSheetSetType = "SEASON" | "STOCK";

export const LineSheetSetTypes: LineSheetSetType[] = ["SEASON", "STOCK"];

export interface LineSheetSetPair {
  lineSheetSet: LineSheetSet;
  orderSheetSet: OrderSheetSet | null;
}

export interface LightLineSheetSetPair {
  lineSheetSet: LightLineSheetSet;
  orderSheetSet: LightOrderSheetSet | null;
}

export interface LightLineSheetPair {
  lineSheet: LightLineSheet;
  orderSheets: LightOrderSheet[];
}

export interface LineSheetSet {
  id: number;
  name: string;
  season: string;
  issuedAt: Date;
  issuedByBoutique: Boutique;
  issuedByBrand: Brand | null;
  issuedToBuyers: { id: number; name: string }[];
  status: LineSheetSetStatus;
  reviewStatus: ReviewStatus | null;
  compressionStatus: CompressionStatus | null;
  type: LineSheetSetType;
  validUntil: Date;
  currency: string;
  orderRules: string[];
  attachments: StoredObject[];
  lineSheets: LineSheet[];
  createdByCompany: Agency;
  createdAt: Date;
  updatedAt: Date;
  publishedAt: Date | null;
  externalFileLinks: string[];
  compressedImportedFile: StoredObject | null;
  isRead: boolean;
  isDeleted: boolean;
}

export interface LightLineSheetSet extends Omit<LineSheetSet, "lineSheets"> {
  lineSheets: LightLineSheet[];
}

export type LineSheetType = "LINE_SHEET" | "ORDER_FORM";

export type ReviewStatus = "SKIPPED" | "PENDING" | "APPROVED" | "REJECTED";
export const ReviewStatuses: ReviewStatus[] = [
  "SKIPPED",
  "PENDING",
  "APPROVED",
  "REJECTED",
];

export type CompressionStatus = "PENDING" | "COMPLETED" | "FAILED";

export interface LineSheet {
  id: number;
  type: LineSheetType;
  name: string;
  file: StoredObject;
  unoFormFile: StoredObject;
  status: LineSheetStatus;
  reviewStatus: ReviewStatus | null;
  compressionStatus: CompressionStatus | null;
  lineSheetTabs: LineSheetTab[];
  updatedAt: Date;
}

export interface LightLineSheet extends Omit<LineSheet, "lineSheetTabs"> {
  lineSheetTabs: LightLineSheetTab[];
}

export interface LineSheetTab {
  index: number;
  name: string;
  products: LineSheetProduct[];
}

export interface LightLineSheetTab extends Omit<LineSheetTab, "products"> {}

export interface DenseLineSheetSet extends Omit<LineSheetSet, "lineSheets"> {
  orderSheetSet?: LightOrderSheetSet;
  lineSheets: DenseLineSheet[];
}

export interface DenseLineSheet extends Omit<LineSheet, "lineSheetTabs"> {
  orderSheets: LightOrderSheet[];
}

export function isLineSheetType(value: string): value is LineSheetSetType {
  const types: LineSheetSetType[] = ["SEASON", "STOCK"];
  return types.some((t) => t === value);
}
