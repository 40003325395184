import useYWR, { YWRKey } from "@/features/ui/hooks/use-ywr";
import { calcUrl } from "@/features/ui/helpers/fetcher-helpers";
import { AxiosRequestConfig } from "axios";
import useFetcher from "@/utils/use-fetcher";

export interface DeleteLineSheetProductImageRequest {
  imageUrl: string;
}

export interface DeleteLineSheetProductImageResponse {
}

export interface DeleteLineSheetProductImagePathParameter {
  lineSheetProductId: number;
}

export const deleteLineSheetProductImageTemplateUrl = `/line-sheet-product/:lineSheetProductId/image-reject`;

export const deleteLineSheetProductImageKey: YWRKey<DeleteLineSheetProductImagePathParameter> =
  {
    url: (parameters) => {
      return calcUrl(deleteLineSheetProductImageTemplateUrl, parameters);
    },
    method: "put",
  };

export default function useDeleteLineSheetProductImage(
  config?: AxiosRequestConfig<DeleteLineSheetProductImageRequest>
) {
  const fetcher = useFetcher<
    DeleteLineSheetProductImageRequest,
    DeleteLineSheetProductImageResponse
  >();

  return useYWR(deleteLineSheetProductImageKey, fetcher, config);
}
