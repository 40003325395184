import { Company, StoredObject } from "@/features/types";
import { GridRow } from "@/features/order-sheet-sets/helpers/app-grid-row-helper";

interface Address {
  country: string;
  state: string;
  city: string;
  streetAddress: string;
  detailAddress: string;
  zipCode: string;
}

export interface Departure extends Address {
  id: number;
  alias: string;
}

export interface IssuedBy extends Address {
  id: number;
  companyName: string;
  businessRegistrationNumber: string;
  representativeName: string;
  representativeEmail: string;
  representativeMobileNumber: string;
  pec: string;
  rea: string;
  eInvoiceUniqueCode: string;
  capital: string;
}

export interface IssuedTo extends Address {
  id: number;
  companyName: string;
  businessRegistrationNumber: string;
}

export interface BankInfo {
  id: number;
  alias: string;
  bankName: string;
  iban: string;
  swiftCode: string;
  branchName: string;
  branchAddress: string;
}

export interface Forwarder {
  id: number;
  name: string;
}

export interface FtaSignature {
  id: number;
  alias: string;
  emphasizedFtaCode: string;
  ftaCode: string;
  signatureFile: StoredObject;
}

export interface TriangularTrade {
  id: number;
  boutique: Pick<Company, "id" | "name">;
  name: string;
  status: "PENDING" | "COMPLETED" | "FAILED";
  originallyIssuedBy: string;
  departure: Departure;
  issuedBy: IssuedBy;
  issuedTo: IssuedTo;
  bankInfo: BankInfo;
  forwarder: Forwarder;
  incoterms: string;
  ftaSignature: FtaSignature;
  issuedOn: Date;
  invoiceNumber: string;
  referenceInvoiceNumber: string;
  markup: number;
  packageInfo: string;
  comment: string;
  importedFiles: StoredObject[];
  exportedFile: StoredObject | null;
  createdAt: Date;
}

export interface TriangularTradeRow extends TriangularTrade, GridRow {}

export const invoiceParsableIssuedByList: string[] = [
  "Exor",
  "Julian",
  "D’aniello",
  "Bini Silvia",
  "G&B",
  "WISE BOUTIQUE",
  "LIDIA SHOPPING",
  "NUGNES",
  "POZZILEI",
  "CUCCUINI",
  "GAUDENZI BOUTIQUE",
  "FOLLI FOLLIE",
  "PIGIZETA MODA",
  "BIFFI",
  "ZETA GIEFFE SRL",
  "ELEONORA BONUCCI",
  "M INTERNATIONAL",
  "GOLF CLUB",
  "VIETTI",
  "FASHION LUX",
  "FRANZ KRALER",
  "RATTI",
  "BRUNA ROSSO",
];
