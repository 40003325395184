import { useCallback, useEffect, useMemo } from "react";
import { GridRow } from "@/features/order-sheet-sets/helpers/app-grid-row-helper";
import {
  AttachmentRow,
  IsolatedLineSheetRow,
  IsolatedOrderSheetRow,
} from "@/features/line-sheet-sets/detail/isolated-sheet.type";
import {
  isOrderedToAgency,
  LightOrderSheetSet,
} from "@/features/order-sheets/order-sheet.type";
import {
  isDraftOrderSheet,
  isFallbackLineSheet,
  isWritableLineSheet,
} from "@/features/line-sheet-sets/utils/line-sheet-set-utils";
import {
  LineSheetSetStatus,
  LineSheetSetType,
} from "@/features/line-sheet-sets/line-sheet-set.types";
import useIdentity from "@/features/ui/hooks/use-identity";
import useI18nHelper from "@/features/ui/hooks/use-i18n-helper";
import useAppGrid from "@/features/invoices/hooks/use-app-grid";
import { isStoredObject } from "@/features/types";
import useCreateOrderSheetSeamlesslyInfinite from "@/features/line-sheet-sets/hooks/use-create-order-sheet-seamlessly-infinite";
import {
  findYWRInfiniteSegment,
  postProcessYWRInfiniteData,
} from "@/features/ui/helpers/ywr-helpers";
import useAppToasts from "@/features/line-sheet-sets/hooks/use-app-toasts";
import { isErrorResponse } from "@/utils/use-fetcher";
import {
  createOrderSheetSeamlesslyKey,
  CreateOrderSheetSeamlesslyParameter,
  CreateOrderSheetSeamlesslyResponse,
} from "@/features/line-sheet-sets/hooks/use-create-order-sheet-seamlessly";
import { NegativeSequencer } from "@/features/ui/utils/negative-sequencer";
import AppGridRowAttachmentExtraContent from "@/features/ui/app-grid-row-attachment-extra-content";
import {
  downloadUnoFileKey,
  DownloadUnoFileResponse,
} from "@/hooks/use-download-uno-file";
import useDownloadUnoFileInfinite from "@/hooks/use-download-uno-file-infinite";
import useDisclosureWithData from "@/features/order-sheet-sets/hooks/use-disclosure-with-data";
import { UploadOrderSheetsModalProps } from "@/features/order-sheet-sets/upload-order-sheets-modal";
import { CreateFallbackOrderSheetSeamlesslyParameter } from "@/features/line-sheet-sets/hooks/use-create-fallback-order-sheet-seamlessly";
import { useDumbTransactions } from "@/features/ui/dumb-transaction/dumb-transaction-state";
import useRouterHelper from "@/features/ui/hooks/use-router-helper";
import useReparseLineSheetSetInfinite from "@/features/line-sheet-sets/hooks/use-reparse-line-sheet-set-infinite";
import { reparseLineSheetSetKey } from "@/features/line-sheet-sets/hooks/use-reparse-line-sheet-set";
import { reparseLineSheetKey } from "@/features/line-sheet-sets/hooks/use-reparse-line-sheet";
import useReparseLineSheetInfinite from "@/features/line-sheet-sets/hooks/use-reparse-line-sheet-infinite";
import useReportLineSheetInfinite from "@/features/line-sheet-sets/hooks/use-report-line-sheet-infinite";
import { reportLineSheetKey } from "@/features/line-sheet-sets/hooks/use-report-line-sheet";
import { KeyedMutator } from "swr";
import { GetDenseLineSheetSetResponse } from "@/features/line-sheet-sets/hooks/use-get-dense-line-sheet-set";
import { updateLineSheetReviewStatusKey } from "@/features/line-sheet-sets/hooks/use-update-line-sheet-review-status";
import useUpdateLineSheetReviewStatusInfinite from "@/features/line-sheet-sets/hooks/use-update-line-sheet-review-status-infinite";
import { copyOrderSheetKey } from "@/features/order-status/hooks/use-copy-order-sheet";
import useCopyOrderSheetInfinite from "@/features/order-status/hooks/use-copy-order-sheet-infinite";
import { UpdateOrderSheetToBeSubmittedModalProps } from "@/features/order-status/modals/update-order-sheet-to-be-submitted-modal";
import { LineSheetableRow } from "@/features/line-sheet-sets/detail/utils/line-sheetable-mapper";
import useDeleteOrderSheet from "@/features/order-sheet-sets/hooks/use-delete-order-sheet";
import useDeleteOrderSheetSet from "@/features/order-sheet-sets/hooks/use-delete-order-sheet-set";
import useAppDialog from "@/features/ui/hooks/use-app-dialog";

interface UseIsolatedSheetGridProps {
  orderSheetSet?: LightOrderSheetSet;
  lineSheetSet: {
    id: number;
    type?: LineSheetSetType;
    status: LineSheetSetStatus;
    publishedAt?: Date | null;
    issuedByBoutique?: { id: number; name: string };
    isDeleted: boolean;
  };
  mutate?: KeyedMutator<GetDenseLineSheetSetResponse>;
  rows?: LineSheetableRow[];
  setRows?: (
    valueOrUpdater:
      | LineSheetableRow[]
      | ((
          prev: LineSheetableRow[] | undefined
        ) => LineSheetableRow[] | undefined)
  ) => void;
}

export default function useLineSheetableGrid({
  orderSheetSet,
  lineSheetSet,
  mutate,
  rows,
  setRows,
}: UseIsolatedSheetGridProps) {
  const appGrid = useAppGrid();
  const identity = useIdentity();
  const { t, tTitle } = useI18nHelper();
  const { info: showInfo } = useAppToasts({ id: "GLOBAL" });
  const { openDialog } = useAppDialog();

  const { navigateToLineSheetSetDraftPage, navigateToOrderSheetSetDraftPage } =
    useRouterHelper();
  const { error: showError } = useAppToasts();
  const negativeSequencer = useMemo(() => {
    return new NegativeSequencer();
  }, []);
  const disclosureUploadOrderSheetModal =
    useDisclosureWithData<UploadOrderSheetsModalProps>();

  const disclosureUpdateToBeSubmittedModal =
    useDisclosureWithData<UpdateOrderSheetToBeSubmittedModalProps>();

  // API : Download Uno File
  const {
    data: downloadUnoFileData,
    fire: fireDownloadUnoFile,
    clear: clearDownloadUnoFile,
  } = useDownloadUnoFileInfinite();

  useEffect(() => {
    postProcessYWRInfiniteData(
      downloadUnoFileData,
      clearDownloadUnoFile,
      showError,
      () => {}
    );
  }, [downloadUnoFileData, clearDownloadUnoFile, showError]);

  const findDownloadUnoFileSegment = useCallback(
    (unoFileId: number) => {
      return findYWRInfiniteSegment(downloadUnoFileData, downloadUnoFileKey, {
        unoFileId: unoFileId,
      });
    },
    [downloadUnoFileData]
  );

  // END: API : Download Uno File

  // API: Create Order Sheet Seamlessly
  const {
    data: createOrderSheetSeamlesslyData,
    fire: fireCreateOrderSheetSeamlessly,
    clear: clearCreateOrderSheetSeamlessly,
  } = useCreateOrderSheetSeamlesslyInfinite();

  const {
    isLoading: isDeleteOrderSheetLoading,
    fire: fireDeleteOrderSheet,
    data: fireDeleteOrderSheetData,
    error: fireDeleteOrderSheetError,
    clear: clearDeleteOrderSheet,
  } = useDeleteOrderSheet();

  useEffect(() => {
    postProcessYWRInfiniteData(
      createOrderSheetSeamlesslyData,
      clearCreateOrderSheetSeamlessly,
      (error) => {
        showError(error);

        if (isErrorResponse(error) && error.error.code === 409) {
          mutate?.();
        }
      },
      (response: CreateOrderSheetSeamlesslyResponse[]) => {
        //mutateRows();
        if (response.length > 0) {
          const last = response[response.length - 1];
          navigateToOrderSheetSetDraftPage(last._orderSheetSetId!, last.id);
        }
      }
    );
  }, [
    createOrderSheetSeamlesslyData,
    clearCreateOrderSheetSeamlessly,
    showError,
    navigateToOrderSheetSetDraftPage,
    mutate,
  ]);

  const findCreateOrderSheetSegment = useCallback(
    (parameter: CreateOrderSheetSeamlesslyParameter) => {
      return findYWRInfiniteSegment(
        createOrderSheetSeamlesslyData,
        createOrderSheetSeamlesslyKey,
        parameter
      );
    },
    [createOrderSheetSeamlesslyData]
  );

  // END: API: Create Order Sheet Seamlessly

  // API: Reparse Line Sheet Set
  const {
    data: reparseLineSheetSetData,
    fire: fireReparseLineSheetSet,
    clear: clearReparseLineSheetSet,
  } = useReparseLineSheetSetInfinite();

  useEffect(() => {
    postProcessYWRInfiniteData(
      reparseLineSheetSetData,
      clearReparseLineSheetSet,
      showError,
      () => {
        mutate?.();
      }
    );
  }, [reparseLineSheetSetData, clearReparseLineSheetSet, showError, mutate]);

  const findReparseLineSheetSetSegment = useCallback(
    (lineSheetSetId: number) => {
      if (identity?.company?.type === "AGENCY") {
        return findYWRInfiniteSegment(
          reparseLineSheetSetData,
          reparseLineSheetSetKey,
          {
            agencyId: identity.company.id,
            lineSheetSetId,
          }
        );
      }
    },
    [identity, reparseLineSheetSetData]
  );

  // END: API: Reparse Line Sheet Set

  // API: Reparse Line Sheet
  const {
    data: reparseLineSheetData,
    fire: fireReparseLineSheet,
    clear: clearReparseLineSheet,
  } = useReparseLineSheetInfinite();

  useEffect(() => {
    postProcessYWRInfiniteData(
      reparseLineSheetData,
      clearReparseLineSheet,
      showError,
      () => {
        mutate?.();
      }
    );
  }, [reparseLineSheetData, clearReparseLineSheet, showError, mutate]);

  const findReparseLineSheetSegment = useCallback(
    (lineSheetSetId: number, lineSheetId: number) => {
      if (identity?.company?.type === "AGENCY") {
        return findYWRInfiniteSegment(
          reparseLineSheetData,
          reparseLineSheetKey,
          {
            agencyId: identity.company.id,
            lineSheetSetId,
            lineSheetId,
          }
        );
      }
    },
    [identity, reparseLineSheetData]
  );

  // END API: Reparse Line Sheet

  // API: Report Line Sheet
  const {
    data: reportLineSheetData,
    fire: fireReportLineSheet,
    clear: clearReportLineSheet,
  } = useReportLineSheetInfinite();

  useEffect(() => {
    postProcessYWRInfiniteData(
      reportLineSheetData,
      clearReportLineSheet,
      showError,
      () => {
        mutate?.();
      }
    );
  }, [reportLineSheetData, clearReportLineSheet, showError, mutate]);

  const findReportLineSheetSegment = useCallback(
    (lineSheetSetId: number, lineSheetId: number) => {
      if (identity?.company?.type === "AGENCY") {
        return findYWRInfiniteSegment(reportLineSheetData, reportLineSheetKey, {
          agencyId: identity.company.id,
          lineSheetSetId,
          lineSheetId,
        });
      }
    },
    [identity, reportLineSheetData]
  );

  // END API: Report Line Sheet

  // API: Update Line Sheet Review Status
  const {
    data: updateLineSheetReviewStatusData,
    fire: fireUpdateLineSheetReviewStatus,
    clear: clearUpdateLineSheetReviewStatus,
  } = useUpdateLineSheetReviewStatusInfinite();

  useEffect(() => {
    postProcessYWRInfiniteData(
      updateLineSheetReviewStatusData,
      clearUpdateLineSheetReviewStatus,
      showError,
      () => {
        mutate?.();
      }
    );
  }, [
    updateLineSheetReviewStatusData,
    clearUpdateLineSheetReviewStatus,
    showError,
    mutate,
  ]);

  const findUpdateLineSheetReviewStatusSegment = useCallback(
    (lineSheetSetId: number, lineSheetId: number) => {
      if (identity?.company?.type === "AGENCY") {
        return findYWRInfiniteSegment(
          updateLineSheetReviewStatusData,
          updateLineSheetReviewStatusKey,
          {
            agencyId: identity.company.id,
            lineSheetSetId,
            lineSheetId,
          }
        );
      }
    },
    [identity, updateLineSheetReviewStatusData]
  );

  //END API: Update Line Sheet Review Status

  // API: Copy Order Sheet

  const {
    fire: fireCopyOrderSheet,
    data: copyOrderSheetData,
    clear: clearCopyOrderSheet,
  } = useCopyOrderSheetInfinite();

  useEffect(() => {
    postProcessYWRInfiniteData(
      copyOrderSheetData,
      clearCopyOrderSheet,
      showError,
      () => {
        mutate?.();
      }
    );
  }, [copyOrderSheetData, clearCopyOrderSheet, showError, mutate]);

  const findCopyOrderSheetSegment = useCallback(
    (parameter: { orderSheetSetId: number; orderSheetId: number }) => {
      if (identity?.company?.type === "AGENCY") {
        return findYWRInfiniteSegment(copyOrderSheetData, copyOrderSheetKey, {
          agencyId: identity.company.id,
          orderSheetSetId: parameter.orderSheetSetId,
          _sourceOrderSheetId: parameter.orderSheetId,
        });
      }
    },
    [identity, copyOrderSheetData]
  );

  // END API: Copy Order Sheet

  const {
    findCreateFallbackOrderSheetSeamlesslyTransaction,
    findUpdateFallbackOrderSheetTransaction,
  } = useDumbTransactions();

  const isWritable = useCallback(
    (sheetRow: IsolatedOrderSheetRow | IsolatedLineSheetRow) => {
      function isOrderSheetRow(
        row: IsolatedOrderSheetRow | IsolatedLineSheetRow
      ): row is IsolatedOrderSheetRow {
        return (row as IsolatedOrderSheetRow).lineSheet !== undefined;
      }

      if (lineSheetSet?.type) {
        return isWritableLineSheet(
          isOrderSheetRow(sheetRow) ? sheetRow.lineSheet : sheetRow,
          {
            type: lineSheetSet.type,
            publishedAt: lineSheetSet.publishedAt || null,
          },
          orderSheetSet
            ? {
                status: orderSheetSet.status,
                orderSheets: orderSheetSet.orderSheets,
              }
            : undefined
        );
      }
    },
    [lineSheetSet.publishedAt, lineSheetSet.type, orderSheetSet]
  );

  const writeDraftByOrderSheet = useCallback(
    (row: GridRow) => {
      if (identity?.company?.type === "BUYER" && orderSheetSet) {
        const orderSheetRow = row as IsolatedOrderSheetRow;
        if (
          !isFallbackLineSheet(orderSheetRow.lineSheet) &&
          isWritable(orderSheetRow)
        ) {
          const parameter: CreateOrderSheetSeamlesslyParameter = {
            by: "BUYER",
            companyId: identity.company.id,
            lineSheetSetId: lineSheetSet.id,
            lineSheetId: orderSheetRow.lineSheet.id,
            orderSheetSetId: orderSheetSet.id,
            index: negativeSequencer.next(),
          };

          return {
            name: "새 주문서 작성",
            isLoading: findCreateOrderSheetSegment(parameter)?.isLoading,
            onClick: () => {
              fireCreateOrderSheetSeamlessly(parameter, {});
            },
          };
        }
      }
    },
    [
      identity,
      orderSheetSet,
      isWritable,
      lineSheetSet.id,
      negativeSequencer,
      findCreateOrderSheetSegment,
      fireCreateOrderSheetSeamlessly,
    ]
  );

  const deleteLineSheetable = useCallback(
    (row: GridRow) => {
      return {
        name: "delete",
        iconSrc: "/icons/icon_x_letter.svg",
        onClick: () => {
          if (setRows) {
            setRows((prev) => {
              return prev?.filter((r) => r.id !== row.id);
            });
          }
        },
      };
    },
    [setRows]
  );

  //주문이후에도 보기가 있어야하네.. fallback일때는 download만
  const writeDraftOrViewLineSheet = useCallback(
    (row: GridRow) => {
      if (identity?.company?.type === "BUYER") {
        const lineSheetRow = row as IsolatedLineSheetRow;

        if (lineSheetRow.status) {
          if (isFallbackLineSheet({ status: lineSheetRow.status })) {
            if (isWritable(lineSheetRow)) {
              //fallback
              const parameter: CreateFallbackOrderSheetSeamlesslyParameter = {
                by: identity.company.type,
                companyId: identity.company.id,
                lineSheetSetId: lineSheetSet.id,
                lineSheetId: lineSheetRow.id,
                orderSheetSetId: orderSheetSet?.id || -1,
                index: negativeSequencer.next(),
              };

              return {
                name: tTitle("upload_line_sheet"),
                isLoading:
                  findCreateFallbackOrderSheetSeamlesslyTransaction(parameter)
                    ?.isLoading,
                onClick: () => {
                  disclosureUploadOrderSheetModal.openWithData((prev) => {
                    return {
                      ...parameter,
                      name: lineSheetRow.name,
                      lineSheetSetType: lineSheetSet.type!,
                      orderSheetId: -1,
                    };
                  });
                },
              };
            }
          } else {
            if (isWritable(lineSheetRow)) {
              const parameter: CreateOrderSheetSeamlesslyParameter = {
                by: "BUYER",
                companyId: identity.company.id,
                lineSheetSetId: lineSheetSet.id,
                lineSheetId: lineSheetRow.id,
                orderSheetSetId: orderSheetSet?.id || -1,
                index: 0,
              };

              return {
                name: tTitle("view"),
                isLoading: findCreateOrderSheetSegment(parameter)?.isLoading,
                onClick: () => {
                  fireCreateOrderSheetSeamlessly(parameter, {});
                },
              };
            } else {
              //진짜 보기
              return {
                name: tTitle("view"),
                onClick: () => {
                  if (orderSheetSet) {
                    navigateToOrderSheetSetDraftPage(
                      orderSheetSet.id,
                      undefined,
                      row.id
                    );
                  }
                },
              };
            }
          }
        }
      } else if (identity?.company?.type === "AGENCY") {
        const lineSheetRow = row as IsolatedLineSheetRow;
        if (
          !isFallbackLineSheet({
            status: lineSheetRow.status!,
          })
        ) {
          return {
            name: tTitle("view"),
            onClick: () => {
              navigateToLineSheetSetDraftPage(lineSheetSet.id, lineSheetRow.id);
            },
          };
        }
      }
    },
    [
      identity,
      isWritable,
      lineSheetSet.id,
      lineSheetSet.type,
      orderSheetSet,
      negativeSequencer,
      tTitle,
      findCreateFallbackOrderSheetSeamlesslyTransaction,
      disclosureUploadOrderSheetModal,
      findCreateOrderSheetSegment,
      fireCreateOrderSheetSeamlessly,
      navigateToOrderSheetSetDraftPage,
      navigateToLineSheetSetDraftPage,
    ]
  );

  const uploadFallbackOrderSheet = useCallback(
    (row: GridRow) => {
      const orderSheetRow = row as IsolatedOrderSheetRow;
      if (identity?.company?.type === "BUYER" && orderSheetSet) {
        if (
          isDraftOrderSheet(orderSheetRow) &&
          isFallbackLineSheet(orderSheetRow.lineSheet)
        ) {
          const segment = findUpdateFallbackOrderSheetTransaction({
            buyerId: identity.company.id,
            orderSheetSetId: orderSheetSet.id,
            orderSheetId: orderSheetRow.id,
          });

          return {
            name: tTitle("common:upload_line_sheet"),
            isLoading: segment?.isLoading || false,
            onClick: () => {
              disclosureUploadOrderSheetModal.openWithData((prev) => {
                return {
                  name: orderSheetRow.lineSheet.name,
                  lineSheetSetType: lineSheetSet.type!,
                  lineSheetSetId: lineSheetSet.id,
                  orderSheetSetId: orderSheetSet.id,
                  lineSheetId: orderSheetRow.lineSheet.id,
                  orderSheetId: orderSheetRow.id,
                  index: 0,
                };
              });
            },
          };
        }
      }
    },
    [
      identity,
      orderSheetSet,
      findUpdateFallbackOrderSheetTransaction,
      tTitle,
      disclosureUploadOrderSheetModal,
      lineSheetSet.type,
      lineSheetSet.id,
    ]
  );

  const downloadAttachment = useCallback(
    (row: GridRow) => {
      const attachmentRow = row as AttachmentRow;
      return appGrid.actions.withDownloadUnoFile<AttachmentRow>((row) => {
        if (isStoredObject(row)) {
          return row;
        }
        return null;
      })(attachmentRow);
    },
    [appGrid.actions]
  );

  const downloadLineSheet = useCallback(
    (
      row: GridRow,
      postProcess?: (response: DownloadUnoFileResponse) => void
    ) => {
      if (row._rowType === "LineSheet") {
        const lineSheetRow = row as IsolatedLineSheetRow;
        const isFallback = isFallbackLineSheet({
          status: lineSheetRow.status!,
        });

        return appGrid.actions.withDownloadUnoFile<IsolatedLineSheetRow>(
          (row) => {
            if (row.file && isStoredObject(row.file)) {
              return row.file;
            }
            return null;
          },
          "origin_download",
          (response) => {
            if (!orderSheetSet || !isOrderedToAgency(orderSheetSet)) {
              if (isFallback && identity?.company?.type === "BUYER") {
                showInfo(t("common:how_to_order.fallback2"));
              }
            }
          }
        )(row);
      } else if (row._rowType === "OrderSheet") {
        return appGrid.actions.withDownloadUnoFile<IsolatedOrderSheetRow>(
          (row) => row.lineSheet.file,
          "origin_download"
        )(row);
      }
    },
    [appGrid.actions, orderSheetSet, identity?.company?.type, showInfo, t]
  );


  const downloadLineSheetUnoForm = useCallback(
    (
      row: GridRow,
      postProcess?: (response: DownloadUnoFileResponse) => void
    ) => {
      if (row._rowType === "LineSheet") {
        const lineSheetRow = row as IsolatedLineSheetRow;
        const isFallback = isFallbackLineSheet({
          status: lineSheetRow.status!,
        });

        return appGrid.actions.withDownloadUnoFile<IsolatedLineSheetRow>(
          (row) => {
            if (row.unoFormFile && isStoredObject(row.unoFormFile)) {
              return row.unoFormFile;
            }
            return null;
          },
          "uno_form_download",
          (response) => {
            if (!orderSheetSet || !isOrderedToAgency(orderSheetSet)) {
              if (isFallback && identity?.company?.type === "BUYER") {
                showInfo(t("common:how_to_order.fallback2"));
              }
            }
          }
        )(row);
      } else if (row._rowType === "OrderSheet") {
        return appGrid.actions.withDownloadUnoFile<IsolatedOrderSheetRow>(
          (row) => row.lineSheet.unoFormFile,
          "uno_form_download"
        )(row);
      }
    },
    [appGrid.actions, orderSheetSet, identity?.company?.type, showInfo, t]
  );


  const downloadOrderSheet = useCallback(
    (row: GridRow) => {
      const orderSheetRow = row as IsolatedOrderSheetRow;

      if (isFallbackLineSheet(orderSheetRow.lineSheet)) {
        return appGrid.actions.withDownloadUnoFile<IsolatedOrderSheetRow>(
          (row) => row.uploadedFile,
          "common:line_sheet_download"
        )(orderSheetRow);
      } else if (orderSheetRow.exportedFile) {
        return appGrid.actions.withDownloadUnoFile<IsolatedOrderSheetRow>(
          (row) => row.exportedFile,
          "common:line_sheet_download"
        )(orderSheetRow);
      }
    },
    [appGrid.actions]
  );

  const deleteOrderSheet = useCallback(
    (row: GridRow) => {
      const orderSheetRow = row as IsolatedOrderSheetRow;
      if (identity?.company?.type === "BUYER") {
        if (orderSheetRow.status === "WORKING_DRAFT") {
          return {
            name: tTitle("delete_order_sheet"),
            iconSrc: "/icons/icon_x_letter.svg",
            onClick: async () => {
              const result = await openDialog({
                title: tTitle("delete_order_sheet"),
                message: tTitle("common\:delete_order_sheet.message"),
                confirmName: tTitle("delete.do"),
              });

              if (!result) {
                return;
              }
              await fireDeleteOrderSheet(
                {
                  buyerId: identity!.company!.id,
                  orderSheetSetId: orderSheetSet!.id,
                  orderSheetId: orderSheetRow.id,
                },
                {}
              );
              mutate?.();
              clearDeleteOrderSheet();
            },
          };
        }
      }
    },
    [clearDeleteOrderSheet, fireDeleteOrderSheet, identity, mutate, openDialog, orderSheetSet, tTitle]
  );

  const editOrViewOrderSheet = useCallback(
    (row: GridRow) => {
      const orderSheetRow = row as IsolatedOrderSheetRow;

      if (orderSheetSet && !isFallbackLineSheet(orderSheetRow.lineSheet)) {
        const isDraft = isDraftOrderSheet(orderSheetRow);
        const isEditable = isDraft && identity?.company?.type === "BUYER";

        return {
          //보기에서도 수정이 가능함으로 edit삭제
          name: tTitle("view"),
          onClick: () => {
            navigateToOrderSheetSetDraftPage(
              orderSheetSet.id,
              orderSheetRow.id
            );
          },
        };
      }
    },
    [
      identity?.company?.type,
      navigateToOrderSheetSetDraftPage,
      orderSheetSet,
      tTitle,
    ]
  );

  const viewLineSheet = useCallback(
    (row: GridRow) => {
      const lineSheetRow = row as IsolatedLineSheetRow;

      if (
        !isFallbackLineSheet({
          status: lineSheetRow.status!,
        })
      ) {
        return {
          name: tTitle("view"),
          onClick: () => {
            navigateToLineSheetSetDraftPage(lineSheetSet.id, lineSheetRow.id);
          },
        };
      }
    },
    [lineSheetSet.id, navigateToLineSheetSetDraftPage, tTitle]
  );

  const reparseLineSheet = useCallback(
    (row: GridRow) => {
      const lineSheetRow = row as IsolatedLineSheetRow;
      const isPublished = lineSheetSet.publishedAt !== null;
      const isDeleted = lineSheetSet.isDeleted;
      if (identity?.company?.type === "AGENCY" && !isPublished && !isDeleted) {
        if (
          lineSheetRow.status === "PARSING_PENDING" ||
          lineSheetRow.status === "PARSING_FAILED"
        ) {
          return {
            name: tTitle("reparse"),
            isLoading:
              findReparseLineSheetSegment(lineSheetSet.id, lineSheetRow.id)
                ?.isLoading || false,
            onClick: () => {
              fireReparseLineSheet(
                {
                  agencyId: identity!.company!.id,
                  lineSheetSetId: lineSheetSet.id,
                  lineSheetId: lineSheetRow.id,
                },
                {}
              );
            },
          };
        }
      }
    },
    [
      tTitle,
      findReparseLineSheetSegment,
      fireReparseLineSheet,
      identity,
      lineSheetSet.id,
      lineSheetSet.publishedAt,
      lineSheetSet.isDeleted,
    ]
  );

  const forceLineSheetParsingFailed = useCallback(
    (row: GridRow) => {
      const isPublished = lineSheetSet.publishedAt !== null;
      const isDeleted = lineSheetSet.isDeleted;
      if (identity?.company?.type === "AGENCY") {
        const lineSheetRow = row as IsolatedLineSheetRow;
        if (lineSheetRow.status === "PARSED" && !isPublished && !isDeleted) {
          const segment = findReportLineSheetSegment(
            lineSheetSet.id,
            lineSheetRow.id
          );
          return {
            name: tTitle("common:force_parsing_failed"),
            isLoading: segment?.isLoading || false,
            onClick: () => {
              fireReportLineSheet(
                {
                  agencyId: identity!.company!.id,
                  lineSheetSetId: lineSheetSet.id,
                  lineSheetId: lineSheetRow.id,
                },
                {}
              );
            },
          };
        }
      }
    },
    [
      findReportLineSheetSegment,
      fireReportLineSheet,
      identity,
      lineSheetSet.id,
      lineSheetSet.publishedAt,
      tTitle,
      lineSheetSet.isDeleted,
    ]
  );

  const pendReview = useCallback(
    (row: GridRow) => {
      if (identity?.company?.type === "AGENCY") {
        const lineSheetRow = row as IsolatedLineSheetRow;
        const isPublished = lineSheetSet.publishedAt !== null;
        const isDeleted = lineSheetSet.isDeleted;
        const reviewStatus = lineSheetRow.reviewStatus;
        if (
          !isPublished &&
          !isDeleted &&
          (reviewStatus === "APPROVED" || reviewStatus === "REJECTED")
        ) {
          const segment = findUpdateLineSheetReviewStatusSegment(
            lineSheetSet.id,
            lineSheetRow.id
          );
          return {
            name: tTitle("review_pend.do"),
            isLoading: segment?.isLoading || false,
            onClick: () => {
              fireUpdateLineSheetReviewStatus(
                {
                  agencyId: identity!.company!.id,
                  lineSheetSetId: lineSheetSet.id,
                  lineSheetId: lineSheetRow.id,
                },
                {
                  reviewStatus: "PENDING",
                }
              );
            },
          };
        }
      }
    },
    [
      findUpdateLineSheetReviewStatusSegment,
      fireUpdateLineSheetReviewStatus,
      identity,
      lineSheetSet.id,
      lineSheetSet.publishedAt,
      tTitle,
      lineSheetSet.isDeleted,
    ]
  );

  const approveReview = useCallback(
    (row: GridRow) => {
      if (identity?.company?.type === "AGENCY") {
        const lineSheetRow = row as IsolatedLineSheetRow;
        const isPublished = lineSheetSet.publishedAt !== null;
        const isDeleted = lineSheetSet.isDeleted;
        const reviewStatus = lineSheetRow.reviewStatus;
        if (
          !isPublished &&
          !isDeleted &&
          (reviewStatus === "PENDING" || reviewStatus === "REJECTED")
        ) {
          const segment = findUpdateLineSheetReviewStatusSegment(
            lineSheetSet.id,
            lineSheetRow.id
          );
          return {
            name: tTitle("review_approve.do"),
            isLoading: segment?.isLoading || false,
            onClick: () => {
              fireUpdateLineSheetReviewStatus(
                {
                  agencyId: identity!.company!.id,
                  lineSheetSetId: lineSheetSet.id,
                  lineSheetId: lineSheetRow.id,
                },
                {
                  reviewStatus: "APPROVED",
                }
              );
            },
          };
        }
      }
    },
    [
      findUpdateLineSheetReviewStatusSegment,
      fireUpdateLineSheetReviewStatus,
      identity,
      lineSheetSet.id,
      lineSheetSet.publishedAt,
      tTitle,
      lineSheetSet.isDeleted,
    ]
  );

  const rejectReview = useCallback(
    (row: GridRow) => {
      if (identity?.company?.type === "AGENCY") {
        const lineSheetRow = row as IsolatedLineSheetRow;
        const isPublished = lineSheetSet.publishedAt !== null;
        const isDeleted = lineSheetSet.isDeleted;
        const reviewStatus = lineSheetRow.reviewStatus;
        if (
          !isPublished &&
          !isDeleted &&
          (reviewStatus === "PENDING" || reviewStatus === "APPROVED")
        ) {
          const segment = findUpdateLineSheetReviewStatusSegment(
            lineSheetSet.id,
            lineSheetRow.id
          );
          return {
            name: tTitle("review_reject.do"),
            isLoading: segment?.isLoading || false,
            onClick: () => {
              fireUpdateLineSheetReviewStatus(
                {
                  agencyId: identity!.company!.id,
                  lineSheetSetId: lineSheetSet.id,
                  lineSheetId: lineSheetRow.id,
                },
                {
                  reviewStatus: "REJECTED",
                }
              );
            },
          };
        }
      }
    },
    [
      findUpdateLineSheetReviewStatusSegment,
      fireUpdateLineSheetReviewStatus,
      identity,
      lineSheetSet.id,
      lineSheetSet.publishedAt,
      tTitle,
      lineSheetSet.isDeleted,
    ]
  );

  const copyOrderSheet = useCallback(
    (row: GridRow) => {
      if (identity?.company?.type === "AGENCY" && orderSheetSet) {
        const orderSheetRow = row as IsolatedOrderSheetRow;
        if (orderSheetRow.status === "MODIFICATION_REQUIRED") {
          return {
            name: tTitle("copy"),
            isLoading:
              findCopyOrderSheetSegment({
                orderSheetSetId: orderSheetSet.id,
                orderSheetId: orderSheetRow.id,
              })?.isLoading || false,
            onClick: () => {
              fireCopyOrderSheet(
                {
                  agencyId: identity!.company!.id,
                  orderSheetSetId: orderSheetSet.id,
                  _sourceOrderSheetId: orderSheetRow.id,
                },
                {
                  "sourceOrderSheet.id": orderSheetRow.id,
                }
              );
            },
          };
        }
      }
    },
    [
      findCopyOrderSheetSegment,
      fireCopyOrderSheet,
      identity,
      orderSheetSet,
      tTitle,
    ]
  );

  const updateToBeSubmitted = useCallback(
    (row: GridRow) => {
      const orderSheetRow = row as IsolatedOrderSheetRow;
      if (
        identity?.company?.type === "AGENCY" &&
        orderSheetSet &&
        [
          "INITIAL_DRAFT",
          "WORKING_DRAFT",
          "ORDERED_TO_AGENCY"
        ].includes(orderSheetRow.status) &&
        lineSheetSet.issuedByBoutique
      ) {
        return {
          name: tTitle("update_to_be_submitted"),
          onClick: () => {
            const excludeIds = orderSheetSet.orderSheets
              .filter((orderSheet) => {
                return (
                  orderSheet.toBeSubmitted !== null &&
                  orderSheet.id !== orderSheetRow.id &&
                  orderSheet.lineSheet.id === orderSheetRow.lineSheet.id &&
                  ![
                    "CANCELED",
                    "MODIFICATION_REQUIRED",
                    "MODIFICATION_COMPLETED",
                  ].includes(orderSheet.status)
                );
              })
              .map((orderSheet) => orderSheet.toBeSubmitted!.id);

            disclosureUpdateToBeSubmittedModal.openWithData((prev) => {
              return {
                agencyId: identity!.company!.id,
                orderSheetSetId: orderSheetSet!.id,
                orderSheetId: orderSheetRow.id,
                toBeUpdateOrderSheet: {
                  id: orderSheetRow.id,
                  name: orderSheetRow.lineSheet.name,
                  orderedBy: orderSheetSet.createdByCompany.name,
                  issuedBy: lineSheetSet.issuedByBoutique!.name,
                  toBeSubmitted: orderSheetRow.toBeSubmitted,
                },
                excludeCompanyIds: excludeIds,
                onUpdate: () => {
                  mutate?.();
                },
                value: orderSheetRow.toBeSubmitted,
              };
            });
          },
        };
      }
    },
    [
      disclosureUpdateToBeSubmittedModal,
      identity,
      lineSheetSet.issuedByBoutique,
      mutate,
      orderSheetSet,
      tTitle,
    ]
  );

  //extraContents
  const orderSheetExtraContents = useCallback(
    (_row: GridRow) => {
      const orderSheetRow = _row as IsolatedOrderSheetRow;
      const uploadedFile = orderSheetRow.uploadedFile;
      if (uploadedFile) {
        const segment = findDownloadUnoFileSegment(uploadedFile.id);
        return [
          <AppGridRowAttachmentExtraContent
            key={"UploadedFile"}
            name={uploadedFile.name}
            isLoading={segment?.isLoading || false}
            onClick={() => {
              fireDownloadUnoFile(
                {
                  unoFileId: uploadedFile.id,
                },
                {}
              );
            }}
          />,
        ];
      }
      return [];
    },
    [findDownloadUnoFileSegment, fireDownloadUnoFile]
  );

  const extraContents = useMemo(() => {
    return {
      orderSheetExtraContents,
    };
  }, [orderSheetExtraContents]);

  const actions = useMemo(() => {
    return {
      deleteLineSheetable,
      writeDraftOrViewLineSheet,
      writeDraftByOrderSheet,
      uploadFallbackOrderSheet,
      downloadLineSheet,
      downloadLineSheetUnoForm,
      downloadOrderSheet,
      downloadAttachment,
      editOrViewOrderSheet,
      viewLineSheet,
      forceLineSheetParsingFailed,
      reparseLineSheet,
      deleteOrderSheet,
      approveReview,
      rejectReview,
      pendReview,
      copyOrderSheet,
      updateToBeSubmitted,
    };
  }, [
    deleteLineSheetable,
    writeDraftOrViewLineSheet,
    writeDraftByOrderSheet,
    uploadFallbackOrderSheet,
    downloadLineSheet,
    downloadLineSheetUnoForm,
    downloadOrderSheet,
    downloadAttachment,
    editOrViewOrderSheet,
    viewLineSheet,
    forceLineSheetParsingFailed,
    reparseLineSheet,
    deleteOrderSheet,
    approveReview,
    rejectReview,
    pendReview,
    copyOrderSheet,
    updateToBeSubmitted,
  ]);

  return {
    disclosureUploadOrderSheetModal,
    disclosureUpdateToBeSubmittedModal,
    actions,
    extraContents,
  };
}
