import { FlatProductImageUrl } from "@/features/line-sheet-sets/helpers/sheet-state";
import ProductImageLabel from "@/features/order-sheet-sets/product-image-label";
import { Box, Image, Tooltip } from "@chakra-ui/react";
import { AppUtils } from "@/features/ui/utils/app-utils";
import AppLabel from "@/features/ui/app-label";
import { PRODUCT_IMAGE_SIZE } from "@/features/order-sheet-sets/product-image-cell";
import { toLower, toTitle } from "@/utils/case";
import SVG from "react-inlinesvg";
import AppIconButton from "@/features/line-sheet-sets/app-icon-button";
import useAppDialog from "@/features/ui/hooks/use-app-dialog";
import useI18nHelper from "@/features/ui/hooks/use-i18n-helper";
import useDeleteOrderSheet from "@/features/order-sheet-sets/hooks/use-delete-order-sheet";
import useDeleteLineSheetProductImage from "@/features/order-sheet-sets/hooks/use-delete-line-sheet-image";
import useAppToasts from "@/features/line-sheet-sets/hooks/use-app-toasts";
import { useEffect } from "react";
import useIdentity from "@/features/ui/hooks/use-identity";

interface ProductImageProps {
  value: FlatProductImageUrl;
  mutate: () => void;
  isLineSheet: boolean;
}

export default function ProductImage({ value, mutate, isLineSheet }: ProductImageProps) {
  const { openDialog, closeDialog } = useAppDialog();
  const { error: showError } = useAppToasts();
  const productId = value.productId;
  const identity = useIdentity();
  const isBuyer = identity?.company?.type === "BUYER";



  const {
    isLoading: isDeleteLineSheetProductImageLoading,
    fire: fireDeleteLineSheetProductImage,
    data: fireDeleteLineSheetProductImageData,
    error: fireDeleteLineSheetProductImageError,
    clear: clearDeleteLineSheetProductImage,
  } = useDeleteLineSheetProductImage();

  useEffect(() => {
    if (fireDeleteLineSheetProductImageError) {
      showError(fireDeleteLineSheetProductImageError);
      clearDeleteLineSheetProductImage();
    }
  }, [clearDeleteLineSheetProductImage, fireDeleteLineSheetProductImageError, showError]);


  return (
    <Tooltip
      placement={"right"}
      backgroundColor={`var(--gray-dark, #444440)`}
      boxShadow={"0px 4px 8px 0px rgba(0, 0, 0, 0.20)"}
      maxWidth={"auto"}
      hasArrow={true}
      p={"0"}
      m={"0"}
      label={<ProductImageLabel value={value} />}
      modifiers={[
        {
          name: "preventOverflow",
          options: {
            padding: 8,
          },
        },
      ]}
    >
      <Box position={"relative"}>
        <Image
          borderRadius={"8px"}
          border={"0.5px solid var(--Pathrade-Gray-Groomy, #8F8F8C)"}
          background={"#fff"}
          position={"relative"}
          flexGrow={"1"}
          alt={"alt"}
          src={AppUtils.getThumbnailUrl(value.url)}
          fallbackSrc={value.url}
          boxSize={`${PRODUCT_IMAGE_SIZE}px`}
          objectFit={"contain"}
          zIndex={"10"}
        />
        <Box position={"absolute"} top={"4px"} left={"4px"} zIndex={"11"}>
          <AppLabel
            isToUpper={false}
            size={"small"}
            nameKey={`product_image.type.${toLower(value.type)}.abbr`}
            level={value.type === "ORIGINAL" ? "PRIMARY" : "SUCCESS"}
          />
        </Box>
        <Box position={"absolute"} top={"0px"} right={"4px"} zIndex={"11"}>
          {!isBuyer && isLineSheet && <AppIconButton
            ariaLabel={"delete tag"}
            icon={"x"}
            size={"medium"}
            onClick={async () => {
              const result = await openDialog({
                title: "이미지 삭제",
                message: "이미지를 정말 삭제하시겠습니까?",
                confirmName: "삭제"
              });

              if (!result) {
                return;
              }

              fireDeleteLineSheetProductImage(
                {
                  lineSheetProductId: productId
                },
                {
                  imageUrl: value.url
                }
              );

              /* mutate 필요 */
              if (mutate) {
                mutate();
              }
            }}
          />}
        </Box>
      </Box>
    </Tooltip>
  );
}
