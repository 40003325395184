import useAppToasts from "@/features/line-sheet-sets/hooks/use-app-toasts";
import useGetDenseOrderSheetSet, {
  GetDenseOrderSheetSetPathParameter,
} from "@/features/order-sheet-sets/hooks/use-get-dense-order-sheet-set";
import useIdentity from "@/features/ui/hooks/use-identity";
import React, { useCallback, useEffect, useMemo } from "react";
import { useListAllOrderSheetStatistics } from "@/features/line-sheet-sets/hooks/use-list-all-order-sheet-statistics";
import ToBeUpdatedOrderSheetList, {
  ToBeUpdatedOrderSheet,
} from "@/features/order-status/modals/to-be-updated-order-sheet-list";
import useSubmitOrderSheetSet, {
  SubmitOrderSheetSetResponse,
} from "@/features/order-sheet-sets/hooks/use-submit-order-sheet-set";
import {
  Box,
  Flex,
  Grid,
  GridItem,
  Heading,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Skeleton,
  Text,
} from "@chakra-ui/react";
import AppButton from "@/features/line-sheet-sets/app-button";
import useI18nHelper from "@/features/ui/hooks/use-i18n-helper";
import { toTitle } from "@/utils/case";
import { t } from "i18next";
import * as amplitude from "@amplitude/analytics-browser";

export interface OrderOrderSheetSetModalProps {
  orderSheetSetId: number;
  orderSheetIds: number[];
  onSubmit: (response: SubmitOrderSheetSetResponse) => void;
  isOpen: boolean;
  onClose: () => void;
}

export default function OrderOrderSheetSetModal({
  orderSheetSetId,
  orderSheetIds,
  onSubmit,
  isOpen,
  onClose,
}: OrderOrderSheetSetModalProps) {
  const identity = useIdentity();
  const { t, tCurrencyString, tTitle } = useI18nHelper();

  const company = useMemo(() => {
    return identity?.company;
  }, [identity]);

  const { error: showError } = useAppToasts();

  const getDenseOrderSheetSetParameter = useMemo(():
    | GetDenseOrderSheetSetPathParameter
    | undefined => {
    if (company?.type === "BUYER" && orderSheetSetId > -1) {
      return {
        by: "BUYER",
        companyId: company.id,
        orderSheetSetId,
        orderSheetIds,
        ts: new Date().toISOString(), // 모달 열릴 때마다 api 호출 하기 위해
      };
    }
  }, [company?.id, company?.type, orderSheetIds, orderSheetSetId]);

  const {
    error: getDenseOrderSheetSetError,
    isLoading: isGetDenseOrderSheetSetLoading,
    isValidating: isGetDenseOrderSheetSetValidating,
    data: getDenseOrderSheetSetData,
  } = useGetDenseOrderSheetSet(
    getDenseOrderSheetSetParameter,
    {},
    { revalidateOnFocus: false }
  );

  useEffect(() => {
    showError(getDenseOrderSheetSetError);
  }, [showError, getDenseOrderSheetSetError]);

  const {
    isLoading: isListAllOrderSheetStatisticsLoading,
    isValidating: isListAllOrderSheetStatisticsValidating,
    error: listAllOrderSheetStatisticsError,
    data: listAllOrderSheetStatisticsData,
  } = useListAllOrderSheetStatistics(
    getDenseOrderSheetSetParameter,
    {},
    { revalidateOnFocus: false }
  );

  useEffect(() => {
    showError(listAllOrderSheetStatisticsError);
  }, [showError, listAllOrderSheetStatisticsError]);

  //수량으로 확인이 필요해졌다, 통계과 함께 연산을 하도록 변경,
  const payload = useMemo(() => {
    if (getDenseOrderSheetSetData && listAllOrderSheetStatisticsData) {
      const orderSheetSet = getDenseOrderSheetSetData;
      const orderSheetStatistics = listAllOrderSheetStatisticsData;

      const toBeOrderedOrderSheets: ToBeUpdatedOrderSheet[] = [];
      const toBeCancelledOrderSheets: ToBeUpdatedOrderSheet[] = [];
      const toBeUpdatedOrderSheetStatistic: {
        name: string;
        totalQuantity: number | "-";
        totalAmount: number | "-";
      }[] = [];

      orderSheetStatistics.forEach((orderSheetStatistic) => {
        const orderSheet = orderSheetSet.orderSheets.find(
          (orderSheet) => orderSheet.id === orderSheetStatistic._orderSheetId
        );
        if (orderSheetIds.includes(orderSheet?.id || -1) === false) {
          return;
        }

        const lineSheet = orderSheetSet.lineSheetSet.lineSheets.find(
          (lineSheet) => lineSheet.id === orderSheet?.lineSheet.id
        );

        if (orderSheet && lineSheet) {
          const obj: ToBeUpdatedOrderSheet = {
            id: orderSheet.id,
            name: orderSheet.lineSheet?.name,
            toBeSubmitted: orderSheet.toBeSubmitted,
            suffix: orderSheet?.suffix,
            issuedBy: orderSheetSet.lineSheetSet.issuedByBoutique.name,
          };

          if (lineSheet.status !== "PARSED") {
            //fallback
            if (
              ["WORKING_DRAFT", "RESUBMIT_REQUIRED"].includes(orderSheet.status)
            ) {
              toBeOrderedOrderSheets.push(obj);
              toBeUpdatedOrderSheetStatistic.push({
                name: obj.name,
                totalQuantity: "-",
                totalAmount: "-",
              });
            } else if (orderSheet.status === "INITIAL_DRAFT") {
              //toBeCancelledOrderSheets.push(obj);
            }
          } else {
            if (
              orderSheet.status === "RESUBMIT_REQUIRED" ||
              orderSheet.status === "WORKING_DRAFT" ||
              orderSheet.status === "INITIAL_DRAFT"
            ) {
              if (orderSheetStatistic.totalOrderedAmount === 0) {
                //toBeCancelledOrderSheets.push(obj);
              } else {
                toBeOrderedOrderSheets.push(obj);
                toBeUpdatedOrderSheetStatistic.push({
                  name: obj.name,
                  totalQuantity: orderSheetStatistic.totalOrderedQuantity,
                  totalAmount: orderSheetStatistic.totalOrderedAmount,
                });
              }
            }
          }
        }
      });

      if (toBeUpdatedOrderSheetStatistic.length > 0) {
        //모두 fallback인경우

        const isAllFallback = toBeUpdatedOrderSheetStatistic.every(
          (statistic) => statistic.totalAmount === "-"
        );

        if (isAllFallback) {
          toBeUpdatedOrderSheetStatistic.unshift({
            name: "Total",
            totalQuantity: "-",
            totalAmount: "-",
          });
        } else {
          const total = toBeUpdatedOrderSheetStatistic.reduce(
            (
              acc,
              cur
            ): { name: string; totalQuantity: number; totalAmount: number } => {
              function func(value: number | "-"): number {
                if (value === "-") {
                  return 0;
                } else {
                  return value;
                }
              }

              return {
                ...acc,
                totalQuantity:
                  func(acc.totalQuantity) + func(cur.totalQuantity),
                totalAmount: func(acc.totalAmount) + func(cur.totalAmount),
              };
            },
            {
              name: "Total",
              totalQuantity: 0,
              totalAmount: 0,
            }
          );
          toBeUpdatedOrderSheetStatistic.unshift(total);
        }
      }

      return {
        toBeOrderedOrderSheets,
        toBeCancelledOrderSheets,
        toBeUpdatedOrderSheetStatistic,
      };
    }

    return {
      toBeOrderedOrderSheets: [],
      toBeCancelledOrderSheets: [],
      toBeUpdatedOrderSheetStatistic: [],
    };
  }, [
    getDenseOrderSheetSetData,
    listAllOrderSheetStatisticsData,
    orderSheetIds,
  ]);

  const {
    isLoading: isSubmitOrderSheetSetLoading,
    fire: fireSubmitOrderSheetSet,
    data: fireSubmitOrderSheetSetData,
    error: fireSubmitOrderSheetSetError,
    clear: clearSubmitOrderSheetSet,
  } = useSubmitOrderSheetSet();

  useEffect(() => {
    showError(fireSubmitOrderSheetSetError);
  }, [showError, fireSubmitOrderSheetSetError]);

  useEffect(() => {
    if (fireSubmitOrderSheetSetData) {
      onSubmit(fireSubmitOrderSheetSetData);
      clearSubmitOrderSheetSet();
      onClose();
    }
  }, [
    clearSubmitOrderSheetSet,
    fireSubmitOrderSheetSetData,
    onClose,
    onSubmit,
  ]);

  const handleTriggerClick = useCallback(() => {
    if (company?.type === "BUYER") {
      fireSubmitOrderSheetSet(
        {
          buyerId: company.id,
          orderSheetSetId,
        },
        {
          orderSheetIds,
        }
      );
      amplitude.track("Order Submitted");
    }
  }, [
    company?.type,
    company?.id,
    fireSubmitOrderSheetSet,
    orderSheetSetId,
    orderSheetIds,
  ]);

  const isGetLoading = useMemo(() => {
    return (
      isGetDenseOrderSheetSetLoading ||
      isGetDenseOrderSheetSetValidating ||
      isListAllOrderSheetStatisticsLoading ||
      isListAllOrderSheetStatisticsValidating
    );
  }, [
    isGetDenseOrderSheetSetLoading,
    isGetDenseOrderSheetSetValidating,
    isListAllOrderSheetStatisticsLoading,
    isListAllOrderSheetStatisticsValidating,
  ]);

  const {
    toBeOrderedOrderSheets,
    toBeCancelledOrderSheets,
    toBeUpdatedOrderSheetStatistic,
  } = payload;

  const isOrderable = toBeOrderedOrderSheets.length > 0;

  const skeleton = useMemo(() => {
    return (
      <Flex
        flexDirection={"column"}
        fontSize={"12px"}
        gap={"16px"}
        color={"#444440"}
      >
        <Heading as={"h3"} fontSize={"inherit"} fontWeight={"400"}>
          Check the order sheets lastly, please.
        </Heading>
        <Grid
          fontSize={"12px"}
          gridTemplateColumns={"0.4fr 1fr"}
          gridAutoRows={"24px"}
          alignItems={"end"}
          justifyItems={"stretch"}
        >
          <GridItem
            borderBottom={"0.5px solid var(--gray, #D4D4D1)"}
            justifySelf={"stretch"}
          >
            Issued By
          </GridItem>
          <GridItem
            borderBottom={"0.5px solid var(--gray, #D4D4D1)"}
            justifySelf={"stretch"}
          >
            Title
          </GridItem>
          <Box height={"80%"} width={"80%"} alignSelf={"center"}>
            <Skeleton height={"100%"} />
          </Box>
          <Box height={"80%"} width={"80%"} alignSelf={"center"}>
            <Skeleton height={"100%"} />
          </Box>
          <Box height={"80%"} width={"80%"} alignSelf={"center"}>
            <Skeleton height={"100%"} />
          </Box>
          <Box height={"80%"} width={"80%"} alignSelf={"center"}>
            <Skeleton height={"100%"} />
          </Box>
        </Grid>
      </Flex>
    );
  }, []);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={"3xl"}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader
          color={"#444440"}
          fontSize={"14px"}
          background={"#F7F9FA"}
          borderBottom={"0.5px solid #8F8F8C"}
        >
          {tTitle("order")}
        </ModalHeader>
        <ModalBody>
          {isGetLoading && skeleton}

          {!isGetLoading && !isOrderable && (
            <Text fontSize={"12px"}>
              {t("common:order_form.messages.empty_draft")}
            </Text>
          )}

          {!isGetLoading && isOrderable && (
            <Flex flexDirection={"column"} gap={"20px"}>
              <Box fontSize={"12px"}>{t("order_form.messages.caution")}</Box>
              {toBeUpdatedOrderSheetStatistic && (
                <Grid
                  gridTemplateColumns={"1fr 0.4fr 0.4fr"}
                  gridAutoRows={"24px"}
                  alignItems={"end"}
                  justifyItems={"stretch"}
                  fontSize={"12px"}
                >
                  <GridItem
                    borderBottom={"0.5px solid var(--gray, #D4D4D1)"}
                    justifySelf={"stretch"}
                  >
                    {toTitle(t("common:line_sheet"))}
                  </GridItem>
                  <GridItem
                    borderBottom={"0.5px solid var(--gray, #D4D4D1)"}
                    justifySelf={"stretch"}
                    textAlign={"end"}
                  >
                    {toTitle(t("common:amount"))}
                  </GridItem>
                  <GridItem
                    borderBottom={"0.5px solid var(--gray, #D4D4D1)"}
                    justifySelf={"stretch"}
                    textAlign={"end"}
                  >
                    {toTitle(t("common:quantity.abbr"))}
                  </GridItem>
                  {toBeUpdatedOrderSheetStatistic
                    .slice(1)
                    .map((statistic, index) => {
                      const { name, totalQuantity, totalAmount } = statistic;
                      const borderBottom = "";
                      return (
                        <React.Fragment
                          key={
                            "SubmitOrderSheetSetModalStatistic_" +
                            orderSheetSetId +
                            "_" +
                            index
                          }
                        >
                          <GridItem
                            borderBottom={borderBottom}
                            justifySelf={"stretch"}
                            overflow={"hidden"}
                            textOverflow={"ellipsis"}
                            whiteSpace={"nowrap"}
                            title={name}
                          >
                            {name}
                          </GridItem>
                          <GridItem
                            borderBottom={borderBottom}
                            justifySelf={"stretch"}
                            textAlign={"end"}
                          >
                            {tCurrencyString(totalAmount)}
                          </GridItem>
                          <GridItem
                            borderBottom={borderBottom}
                            justifySelf={"stretch"}
                            textAlign={"end"}
                          >
                            {totalQuantity}
                          </GridItem>
                        </React.Fragment>
                      );
                    })}
                  {toBeCancelledOrderSheets.map((statistic, index) => {
                    const { name } = statistic;
                    const borderBottom = "";
                    return (
                      <React.Fragment
                        key={
                          "SubmitOrderSheetSetModalStatistic2_" +
                          orderSheetSetId +
                          "_" +
                          index
                        }
                      >
                        <GridItem
                          borderBottom={borderBottom}
                          justifySelf={"stretch"}
                          overflow={"hidden"}
                          textOverflow={"ellipsis"}
                          whiteSpace={"nowrap"}
                          title={name}
                          color={"red"}
                        >
                          *{name}
                        </GridItem>
                        <GridItem
                          borderBottom={borderBottom}
                          justifySelf={"stretch"}
                          textAlign={"end"}
                          color={"red"}
                        >
                          {tCurrencyString(0)}
                        </GridItem>
                        <GridItem
                          borderBottom={borderBottom}
                          justifySelf={"stretch"}
                          textAlign={"end"}
                          color={"red"}
                        >
                          {0}
                        </GridItem>
                      </React.Fragment>
                    );
                  })}
                  <GridItem
                    borderTop={"0.5px solid var(--gray, #D4D4D1)"}
                    justifySelf={"stretch"}
                    fontWeight={"700"}
                  >
                    {tTitle("total")}
                  </GridItem>
                  <GridItem
                    borderTop={"0.5px solid var(--gray, #D4D4D1)"}
                    justifySelf={"stretch"}
                    textAlign={"end"}
                    fontWeight={"700"}
                  >
                    {tCurrencyString(
                      toBeUpdatedOrderSheetStatistic[0].totalAmount
                    )}
                  </GridItem>
                  <GridItem
                    borderTop={"0.5px solid var(--gray, #D4D4D1)"}
                    justifySelf={"stretch"}
                    textAlign={"end"}
                    fontWeight={"700"}
                  >
                    {toBeUpdatedOrderSheetStatistic[0].totalQuantity}
                  </GridItem>
                </Grid>
              )}
              {toBeCancelledOrderSheets.length > 0 && (
                <Text fontSize={"12px"} color={"red"}>
                  * The order sheet with no quantity will be cancelled.
                </Text>
              )}
            </Flex>
          )}
        </ModalBody>
        <ModalFooter display={"flex"} gap={"12px"}>
          <AppButton
            width={"144px"}
            onClick={() => {
              onClose();
            }}
          >
            {tTitle("not_now")}
          </AppButton>
          <AppButton
            width={"144px"}
            variant={"primary"}
            isLoading={isSubmitOrderSheetSetLoading}
            isDisabled={toBeOrderedOrderSheets.length === 0}
            onClick={handleTriggerClick}
          >
            {tTitle("submit")}
          </AppButton>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
